import React, { useRef, useState, useEffect } from 'react';
import intlTelInput, { Plugin } from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axios from 'axios';

import './InputPhone.css';

interface InputPhoneProps {
  placeholder: string,
  name: string,
  value: string,
  className?: string,
  onChange: (newPhone: string) => void,
  onError: (isValid: boolean) => void,
}

export default function InputPhone({
  placeholder, name, value, className, onChange, onError,
}: InputPhoneProps) {
  const [phonePlugin, setPhonePlugin] = useState<Plugin | null>(null);
  const phoneInput = useRef(null);

  useEffect(() => {
    if (phoneInput.current) {
      const newPhonePlugin = intlTelInput(phoneInput.current, {
        initialCountry: 'auto',
        geoIpLookup: async (success) => {
          try {
            const token = 'bd8bf888bf7fd0';
            const { data } = await axios.get(`https://ipinfo.io/json?token=${token}`);
            const countryCode = (data && data.country) ? data.country : 'fr';
            success(countryCode);
          } catch (err) {
            success('fr');
          }
        },
        autoPlaceholder: 'aggressive',
        formatOnDisplay: true,
        separateDialCode: true,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.6/js/utils.js',
      });
      setPhonePlugin(newPhonePlugin);
    }
    return () => setPhonePlugin(null);
  }, [phoneInput.current]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    if (e.target.value.length >= 10) {
      if (phonePlugin?.isValidNumber()) {
        onError(true);
      } else {
        onError(false);
      }
    }
  };

  const handleBlur = () => {
    if (phonePlugin?.isValidNumber()) {
      onChange(phonePlugin?.getNumber());
      onError(true);
    } else {
      onError(false);
    }
  };

  return (
    <input
      placeholder={placeholder}
      name={name}
      id={name}
      value={value}
      ref={phoneInput}
      className={className}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}
