import React from 'react';
import { useTranslation } from 'react-i18next';

import ProjectQAQuestion from './ProjectQAQuestion';

import styles from './ProjectQA.module.css';

export default function ProjectQA() {
  const { t } = useTranslation();
  return (
    <section
      id="howtoinvest"
      className={styles.questions}
    >
      <h2 className={styles.title}>
        {t('questions.title')}
      </h2>
      <ProjectQAQuestion
        question={t('questions.payment')}
        answer={t('questions.paymentAnswer')}
      />
      <ProjectQAQuestion
        question={t('questions.society')}
        answer={t('questions.societyAnswer')}
      />
      <ProjectQAQuestion
        question={t('questions.risk')}
        answer={t('questions.riskAnswer')}
      />
      <ProjectQAQuestion
        question={t('questions.document')}
        answer={t('questions.documentAnswer')}
      />
      <ProjectQAQuestion
        question={t('questions.shareholder')}
        answer={t('questions.shareholderAnswer')}
      />
    </section>
  );
}
