import { trackSimulationUser } from './trackuser';
import { ProjectUser } from '../types/project';

const getEmail = (value: string) => {
  const params = new URLSearchParams(window.location.search);
  let email = params.get('email');
  if (!email) {
    const token = params.get('token');
    if (token && token.includes('@')) {
      email = token;
    } else {
      email = value;
    }
  }
  return email;
};

const handleInvestClick = (id: string, history: number, name: string, userInfo?: ProjectUser) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  let url = `https://payment.ayomi.fr/${id}?montant=${Math.trunc(history)}&token=${token}`;
  if (userInfo || params.get('email')) {
    if (userInfo?.firstName) {
      url = `${url}&prenom=${userInfo.firstName}`;
    }
    if (userInfo?.lastName) {
      url = `${url}&nom=${userInfo.lastName}`;
    }
    if (userInfo?.email || params.get('email')) {
      url = `${url}&email=${userInfo?.email || params.get('email')}`;
    }
    if (userInfo?.phone) {
      url = `${url}&num=${userInfo?.phone}`;
    }
  }
  trackSimulationUser(0, (userInfo?.email) ? getEmail(userInfo?.email) : '', id, name, 33);

  window.open(url);
};

export default handleInvestClick;
