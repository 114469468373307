import React, { useState, useEffect } from 'react';
import {
  Check, Close, Error, ExpandLess, ExpandMore,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';
import LoadingSpinner from 'components/library/LoadingSpinner';
import { getProjectId } from 'utils/project';
import { sendPoll } from 'services/network/api/project';
import * as statuses from 'services/store/states';

import styles from './ProjectManagerUserInterest.module.css';
import ProjectManagerUserInterestPoll from './ProjectManagerUserInterestPoll';
import { PollOption } from './ProjectManagerUserInterestPoll/ProjectManagerUserInterestPoll';
import ProjectManagerUserInterestPollPhone from './ProjectManagerUserInterestPollPhone';
import ProjectManagerUserInterestMail from './ProjectManagerUserInterestMail';
import phoneIcon from './phone.png';

interface Props {
  riPhone: string;
  calendar: string,
}

export default function ProjectManagerUserInterest({ riPhone, calendar }: Props) {
  const [step, setStep] = useState<'poll' | 'phone' | 'email'>('poll');
  const [isVisible, setIsVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [options, setOptionsSelected] = useState<PollOption[]>([]);
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState(statuses.IDLE);

  useEffect(() => {
    const timer = window.setTimeout(() => setIsVisible(true), 60 * 1000);
    return () => clearTimeout(timer);
  }, []);

  const { t } = useTranslation();

  const handleResize = () => {
    if (status === statuses.SUCCESS) {
      setIsVisible(false);
    } else {
      setIsExpanded((curr) => !curr);
    }
  };
  const handlePollSubmit = (data: PollOption[]) => {
    setOptionsSelected(data);
    if (data.filter((option) => !option.isPositive).length > 0) {
      setStatus(statuses.SUCCESS);
    } else {
      setStep('phone');
    }
  };

  const handlePhoneSubmit = (newPhone: string) => {
    setPhone(newPhone);
    setStep('email');
  };

  const handleEmailSubmit = async (email: string) => {
    setStatus(statuses.LOADING);
    try {
      await sendPoll({
        id: getProjectId().toString(),
        phone,
        email,
        options: options.map((option) => option.value).join(';'),
      });
      setStatus(statuses.SUCCESS);
    } catch (err) {
      setStatus(statuses.FAIL);
    }
  };

  const handleReset = () => {
    setStatus(statuses.IDLE);
    setStep('email');
  };

  const openCalendar = () => {
    window.open(calendar, '_blank');
  };

  const FORM_STEP = {
    poll: <ProjectManagerUserInterestPoll onSubmit={handlePollSubmit} />,
    phone: <ProjectManagerUserInterestPollPhone onSubmit={handlePhoneSubmit} />,
    email: <ProjectManagerUserInterestMail onSubmit={handleEmailSubmit} />,
  };

  if (!isVisible) return null;

  return (
    <section
      className={`${styles.poll} ${(isExpanded) ? styles.full : styles.shrink}`}
      data-testid="poll"
    >
      <header>
        <Button
          onClick={handleResize}
          className={styles.position}
          label="expand"
        >
          {(status !== statuses.SUCCESS) && ((isExpanded) ? <ExpandMore /> : <ExpandLess />)}
          {(status === statuses.SUCCESS) && <Close />}
        </Button>
        <p
          className={styles.title}
          hidden={status !== statuses.IDLE}
        >
          {t('interest.question')}
        </p>
      </header>
      {(status === statuses.LOADING) && <LoadingSpinner />}
      {(isExpanded && (status === statuses.IDLE)) && FORM_STEP[step]}
      {
        (status === statuses.SUCCESS)
        && (options.filter((option) => !option.isPositive).length > 0)
        && (
          <div className={`${styles.status} ${styles.success}`}>
            <div className={styles['icon-container']}>
              <Check className={styles.icon} />
            </div>
            <p className={styles.title}>{t('interest.success.title')}</p>
            <p className={styles.description}>
              {t('interest.success.description.negative')}
            </p>
          </div>
        )
      }
      {
        (status === statuses.SUCCESS)
        && !(options.filter((option) => !option.isPositive).length > 0)
        && (
          <div className={styles.callback}>
            <img src={phoneIcon} alt="phone" className={styles.phone} width={26} />
            <p className={styles.title}>{t('callback-ri.poll')}</p>
            <p className={styles.description}>
              {t('callback-ri.calling')}
              <span className={styles.phone}>
                {riPhone}
              </span>
              .
            </p>
            <Button
              onClick={openCalendar}
            >
              {t('callback-ri.rdv')}
            </Button>
          </div>
        )
      }
      {(status === statuses.FAIL) && (
        <div className={`${styles.status} ${styles.error}`}>
          <div className={styles['icon-container']}>
            <Error className={styles.icon} />
          </div>
          <p className={styles.title}>{t('interest.error.title')}</p>
          <p className={styles.description}>{t('interest.error.description')}</p>
          <Button
            className={styles.retry}
            onClick={handleReset}
          >
            {t('interest.error.retry')}
          </Button>
        </div>
      )}
    </section>
  );
}
