const BOT_TOKEN = '5240555968:AAE9f5NA8nHWPwjXCyWOT74NzzNcdCDuXnY';
const CHAT_ID = '-1001883101091';

// eslint-disable-next-line import/prefer-default-export
export const sendMessage = (text: string) => {
  if (process.env.NODE_ENV === 'development') return null;
  return fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${text}&parse_mode=markdown`, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  });
};
