import React from 'react';

import { sendMessage } from 'services/network/api/telegram';

type IProps = Record<string, any>;
interface IState { hasError: boolean, description: string }

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, description: '' };
  }

  static getDerivedStateFromError(error: unknown) {
    sendMessage(
      `*[RENDER ERROR - ${new Date().toLocaleString()}]:* %0A URL: ${window.location.href} %0A Description: ${error}`,
    );
    return { hasError: true, description: `${error}` };
  }

  render() {
    const { hasError, description } = this.state;
    if (hasError) {
      return (
        <div>
          <h1>
            Error
          </h1>
          <p>
            { description }
          </p>
        </div>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
