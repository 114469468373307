import React from 'react';

import styles from './Input.module.css';

export default function Input({
  label, name, className, containerClassName, state, children,
}: {
  label: string, name: string, className?: string,
  containerClassName?: string, state?: string, children: React.ReactNode,
}) {
  return (
    <div className={`${(state) ? styles[state] : ''} ${containerClassName}`}>
      <label className={className} htmlFor={name}>
        { label }
      </label>
      { children }
    </div>
  );
}
