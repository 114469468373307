import React, { useContext } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { ProjectContext } from 'context/project';
import { ProjectBreakdownOfFunds } from 'types/project';

import styles from './ProjectContentFunds.module.css';

interface Props {
  breakdownOfFunds: {
    fr?: { name: string, value: number }[],
    en?: { name: string, value: number }[],
  },
  onChange: (section: string, value: ProjectBreakdownOfFunds) => void,
}

export default function ProjectContentFunds({ breakdownOfFunds, onChange }: Props) {
  const { mode, isAdmin, lang } = useContext(ProjectContext);

  const { t } = useTranslation();

  const baseOptions = {
    colors: ['#FE532D', '#FBC506', '#526AF2', '#F252E2', '#0EDC97'],
    legend: {
      itemMargin: {
        horizontal: -10,
        vertical: 5,
      },
      horizontalAlign: 'center',
      verticalAlign: 'center',
    },
    responsive: [{
      breakpoint: 500,
      options: {
        chart: {
          width: '100%',
          height: '400',
        },
        legend: {
          width: '100%',
        },
      },
    }],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
  };

  if (isAdmin && mode === 'edition') {
    return (
      <div className={styles.section}>
        <h2 className={styles.title}>
          {t('presentation.breakdownOfFunds')}
        </h2>
        {/* Ajouter champs pour ajouter une nouvelle ligne */}
        {
          (breakdownOfFunds[lang] && ((breakdownOfFunds[lang]?.length || 0) > 0))
          && (
            <div className={styles['grid-container']}>
              <div className={`${styles.grid} ${styles['grid-title']}`}>
                <p>{t('presentation.useOfFunds')}</p>
                <p>{t('presentation.percentage')}</p>
              </div>
              {
                breakdownOfFunds[lang]?.map((fund, key) => (
                  <div className={styles.grid} key={fund.value}>
                    <input
                      type="text"
                      value={fund.name}
                      onChange={
                        (e) => {
                          const currentBreakdownOfFunds = breakdownOfFunds[lang];
                          if (currentBreakdownOfFunds && Array.isArray(currentBreakdownOfFunds)) {
                            const newBreakdownOfFunds = currentBreakdownOfFunds
                              .map((curr, index) => {
                                if (index === key) {
                                  return { ...curr, name: e.target.value };
                                }
                                return curr;
                              });
                            onChange(
                              'breakdownOfFunds',
                              { isDisplay: true, ...breakdownOfFunds, [lang]: newBreakdownOfFunds },
                            );
                          }
                        }
                      }
                    />
                    <p>{fund.value}</p>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    );
  }

  if (!breakdownOfFunds[lang] || breakdownOfFunds[lang]?.length === 0) return null;

  return (
    <div className={styles.section}>
      <h2 className={styles.title}>
        Ventilation des fonds
      </h2>
      {
        (breakdownOfFunds[lang] && ((breakdownOfFunds[lang]?.length || 0) > 0))
        && (
          <div className={styles.funds}>
            <Chart
              options={{
                ...baseOptions,
                labels: breakdownOfFunds[lang]?.map((fund) => fund.name),
              } as any}
              series={breakdownOfFunds[lang]?.map((fund) => fund.value)}
              width={400}
              height={400}
              type="donut"
            />
          </div>
        )
      }
      {
        (breakdownOfFunds[lang] && ((breakdownOfFunds[lang]?.length || 0) > 0))
        && (
          <div className={styles['grid-container']}>
            <div className={`${styles.grid} ${styles['grid-title']}`}>
              <p>Utilisation des fonds</p>
              <p>Pourcentage (%)</p>
            </div>
            {
              breakdownOfFunds[lang]?.map((fund) => (
                <div className={styles.grid} key={`${fund.name}-${fund.value}`}>
                  <p>{fund.name}</p>
                  <p>{fund.value}</p>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
}
