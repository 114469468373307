import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputPhone from 'components/InputPhone';
import Input from 'components/library/Input';
import Button from 'components/library/Button';

import styles from './ProjectManagerUserInterestPollPhone.module.css';

interface ProjectManagerUserInterestPollPhoneProps {
  onSubmit: (newPhone: string) => void,
}

export default function ProjectManagerUserInterestPollPhone({
  onSubmit,
}: ProjectManagerUserInterestPollPhoneProps) {
  const [phone, setPhone] = useState('');
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation();

  const handleChange = (newValue: string) => setPhone(newValue);
  const handleError = (isValid: boolean) => {
    if (isValid) {
      setError(undefined);
    } else {
      setError(t('interest.invalidPhone'));
    }
  };
  const handleSubmit = () => {
    if (!error) {
      onSubmit(phone);
    }
  };

  const isDisabled = () => (phone === '') || (error !== undefined);

  return (
    <form className={styles.form}>
      <p>
        {t('interest.phoneNumber')}
      </p>
      <Input
        label={t('interest.phoneNumberInput')}
        name="phone"
        state={(error) ? 'ERROR' : 'DEFAULT'}
        containerClassName={error && 'error'}
      >
        <InputPhone
          placeholder={t('interest.phoneNumberInput')}
          name="phone"
          value={phone}
          onChange={handleChange}
          onError={handleError}
        />
      </Input>
      {
        (error)
        && (
          <p className={styles.hint}>
            { error }
          </p>
        )
      }
      <Button
        className={styles.submit}
        onClick={handleSubmit}
        disabled={isDisabled()}
      >
        {t('interest.send')}
      </Button>
    </form>
  );
}
