import React from 'react';

import {
  Project, ProjectBreakdownOfFunds,
  ProjectContentSection as ProjectContentSectionInterface,
  ProjectDocumentContent,
  ProjectTeamContent,
} from 'types/project';

import ProjectContentSection from './ProjectContentSection';
import ProjectContentTeam from './ProjectContentTeam';
import ProjectContentDocument from './ProjectContentDocument';
import ProjectContentPopUp from './ProjectContentPopUp';
import styles from './ProjectContent.module.css';
import ProjectContentFunds from './ProjectContentFunds';

interface ProjectContentProps {
  project: Project,
  updateProject: (curr: Project) => void,
}

export default function ProjectContent({ project, updateProject }: ProjectContentProps) {
  const handleSubmit = async (newContent: typeof project.content) => {
    const newProject = { ...project, content: newContent };
    updateProject(newProject);
  };

  const handleChange = (
    section: string,
    value: (ProjectContentSectionInterface | ProjectTeamContent
    | ProjectDocumentContent | ProjectBreakdownOfFunds),
  ) => {
    const newContent = {
      ...project.content,
      [section]: {
        ...project.content[section as keyof typeof project.content],
        ...value,
      },
    };

    handleSubmit(newContent);
  };

  return (
    <div
      id="content"
      className={styles.content}
    >
      <ProjectContentSection
        section="project"
        data={project.content.project}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="market"
        data={project.content.market}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="solution"
        data={project.content.solution}
        onChange={handleChange}
      />
      <ProjectContentTeam
        data={project.content.team}
        onChange={handleChange}
      />
      <ProjectContentPopUp
        project={project.content.header.name}
        calendar={project.calendar}
        phone={project.phone}
      />
      <ProjectContentSection
        section="business"
        data={project.content.business}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="metrics"
        data={project.content.metrics}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="partner"
        data={project.content.partner}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="support"
        data={project.content.support}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="social"
        data={project.content.social}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="roadmap"
        data={project.content.roadmap}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="reward"
        data={project.content.reward}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="press"
        data={project.content.press}
        onChange={handleChange}
      />
      <ProjectContentSection
        section="finance"
        data={project.content.finance}
        onChange={handleChange}
      />
      <ProjectContentFunds
        breakdownOfFunds={project.content.breakdownOfFunds}
        onChange={handleChange}
      />
      <ProjectContentDocument
        data={project.content.documents}
        onChange={handleChange}
      />
    </div>
  );
}
