import React, { useRef } from 'react';

import styles from './Modal.module.css';
import useOutsideClickAlert from '../../hooks/useOutsideClick';

interface ModalProps {
  disabledOutsideClick?: boolean,
  disabledScroll?: boolean,
  children: React.ReactNode,
  close?: () => void;
}

export default function Modal({
  children, close, disabledOutsideClick = false, disabledScroll = false,
}: ModalProps) {
  const content = useRef(null);
  const handleClose = () => {
    if (!disabledOutsideClick && close) close();
  };
  useOutsideClickAlert(content, handleClose);
  return (
    <div className={`fadeIn ${styles.background}`}>
      <section className={`${styles.modal} ${(disabledScroll ? styles.noScroll : styles.scroll)}`} ref={content}>
        { children }
      </section>
    </div>
  );
}
