import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Project, ProjectSimulation as ProjectSimulationType } from 'types/project';
import Button from 'components/library/Button';
import ErrorBoundary from 'components/library/ErrorBoundary/ErrorBoundary';
import { ProjectContext } from 'context/project';

import styles from './ProjectSimulation.module.css';
import ProjectSimulationContent from './ProjectSimulationContent';
import ProjectSimulationForm from './ProjectSimulationForm';

interface ProjectSimulationProps {
  project: Project,
  onUpdate: (newProject: Project) => void,
  children: React.ReactNode,
  showDocument?: boolean,
}

export default function ProjectSimulation({
  project, onUpdate, children, showDocument = true,
}: ProjectSimulationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const { mode } = useContext(ProjectContext);

  const openSimulationEditor = () => setIsOpen(true);
  const closeSimulationEditor = () => setIsOpen(false);

  const onSubmit = (value: ProjectSimulationType) => {
    const newProject = {
      ...project,
      content: {
        ...project.content,
        header: {
          ...project.content.header,
          share: `${value.share}€`,
        },
      },
      simulation: value,
    };
    onUpdate(newProject);
  };

  return (
    <div className={styles.layout}>
      {
          (isOpen)
          && (
            <ProjectSimulationForm
              simulation={project.simulation}
              onSubmit={onSubmit}
              close={closeSimulationEditor}
            />
          )
        }
      <div className={styles.main}>
        { children }
      </div>
      <ErrorBoundary>
        <div className={styles.simulation}>
          {
              (mode === 'edition')
              && (
                <Button
                  className={styles.button}
                  onClick={openSimulationEditor}
                >
                  {t('edition.simulation.edit')}
                </Button>
              )
            }
          <ProjectSimulationContent
            simulation={project.simulation}
            header={project.content.header}
            project={project.content.header.name}
            calendar={project.calendar}
            documents={showDocument
              ? project.content.documents
              : { isDisplay: false, fr: [], en: [] }}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
}
