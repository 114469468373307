import React, { useState } from 'react';
import { Help } from '@mui/icons-material';

import Button from 'components/library/Button';

import styles from './ProjectQAQuestion.module.css';

interface ProjectQAQuestionProps {
  question: string,
  answer: string,
}

export default function ProjectQAQuestion({ question, answer }: ProjectQAQuestionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen((curr) => !curr);

  return (
    <>
      <Button
        variant="ghost"
        className={styles.question}
        onClick={handleOpen}
      >
        <Help className={styles.icon} />
        { question }
      </Button>
      {
        (isOpen)
        && (
          <p className={`fadeIn ${styles.answer}`}>
            { answer }
          </p>
        )
      }
    </>
  );
}
