import React from 'react';
import { useTranslation } from 'react-i18next';

import handleInvestClick from 'utils/investClick';
import { ProjectUser } from 'types/project';

import Button from '../library/Button';
import styles from './InvestButton.module.css';

interface InvestButtonProps {
  project: string,
  history: number,
  userInfo?: ProjectUser,
  id: string,
}

export default function InvestButton({
  project, history, userInfo, id,
}: InvestButtonProps) {
  const { t } = useTranslation();

  const handleClick = () => {
    handleInvestClick(id, history, project, userInfo);
  };

  return (
    <Button
      onClick={handleClick}
      className={`${styles.invest} investButton`}
    >
      {t('header.ctaInvest')}
    </Button>
  );
}
