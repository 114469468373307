import React, { useEffect, useMemo, useState } from 'react';

import Loading from 'components/library/Loading';
import LoadingSpinner from 'components/library/LoadingSpinner';
import AuthorizationContext from 'utils/AuthorizationContext';
import trackUser from 'utils/trackuser';

import ProjectManager from './ProjectManager';
import ProjectLayout from './ProjectLayout';
import ProjectError from './ProjectError';

let isFirstTime = true;

export default function Project() {
  const [isAuthorized, setIsAuthorized] = useState(window.connected === 'True');
  const isAuthorizedContext = useMemo(() => ({
    isAuthorized,
    isConnected: window.connected === 'True',
    isRejected: window.notPermission === 1 || window.notPermission === '1',
    setIsAuthorized,
  }), [isAuthorized]);

  useEffect(() => {
    if (isFirstTime) {
      if (window.notPermission === '1' || window.notPermission === 1) {
        trackUser(window.projectName, 133, 'Pop-up transmitter no access');
      }
      isFirstTime = false;
    }
  }, []);

  return (
    <AuthorizationContext.Provider value={isAuthorizedContext}>
      <ProjectManager>
        {
          (status, data, error, setData) => (
            <Loading
              status={status}
              fallback={<LoadingSpinner />}
              errorFallback={<ProjectError error={error} />}
            >
              <ProjectLayout
                data={data}
                setData={setData}
              />
            </Loading>
          )
        }
      </ProjectManager>
    </AuthorizationContext.Provider>
  );
}
