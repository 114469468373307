import { ClientJS } from 'clientjs';

const getIp = () => fetch('https://api.ipify.org?format=json', { mode: 'cors' })
  .then((resp) => resp.json())
  .then(({ ip }) => ip).catch(() => undefined);

const getFingerPrint = async (form?: FormData) => {
  const client = new ClientJS();
  const ip = await getIp();
  const fingerprint = form || new FormData();
  if (ip) {
    fingerprint.append('nav_ip', ip);
  }
  const params = new URLSearchParams(window.location.href);
  params.delete('refresh_token');
  fingerprint.append('nav_url', decodeURIComponent(`${params}`));
  fingerprint.append('nav_fingerprint', client.getFingerprint().toString());
  fingerprint.append('nav_user_agent', client.getUserAgent());
  fingerprint.append('nav_browser', client.getBrowser());
  fingerprint.append('nav_browser_version', client.getBrowserVersion());
  fingerprint.append('nav_engine', client.getEngine());
  fingerprint.append('nav_engine_version', client.getEngineVersion());
  fingerprint.append('nav_os', client.getOS());
  fingerprint.append('nav_os_version', client.getOSVersion());
  fingerprint.append('nav_device', client.getDevice());
  fingerprint.append('nav_device_type', client.getDeviceType());
  fingerprint.append('nav_device_vendor', client.getDeviceVendor());
  fingerprint.append('nav_cpu', client.getCPU());
  fingerprint.append('nav_is_mobile', client.isMobile().toString());
  if (client.isMobile() && client.isMobileMajor()) {
    if (client.isMobileOpera()) {
      fingerprint.append('nav_mobile_type', 'Opera');
    }
    if (client.isMobileWindows()) {
      fingerprint.append('nav_mobile_type', 'Windows');
    }
    if (client.isMobileBlackBerry()) {
      fingerprint.append('nav_mobile_type', 'BlackBerry');
    }
    if (client.isMobileIOS()) {
      if (client.isIphone()) {
        fingerprint.append('nav_mobile_type', 'iphone');
      }
      if (client.isIpad()) {
        fingerprint.append('nav_mobile_type', 'ipad');
      }
      if (client.isIpod()) {
        fingerprint.append('nav_mobile_type', 'ipod');
      }
    }
  }
  fingerprint.append('nav_screen', client.getScreenPrint());
  fingerprint.append('nav_plugins', client.getPlugins());
  fingerprint.append('nav_java_installed', client.isJava().toString());
  fingerprint.append('nav_flash_installed', client.isFlash().toString());
  fingerprint.append('nav_silverlight_installed', client.isSilverlight().toString());
  fingerprint.append('nav_silverlight_version', client.getSilverlightVersion());
  fingerprint.append('nav_mime_types', client.getMimeTypes());
  fingerprint.append('nav_fonts', client.getFonts());
  fingerprint.append('nav_local_storage', client.isLocalStorage().toString());
  fingerprint.append('nav_sessionstorage', client.isSessionStorage().toString());
  fingerprint.append('nav_cookie', client.isCookie() ? document.cookie : {}.toString());
  fingerprint.append('nav_timezone', client.getTimeZone());
  fingerprint.append('nav_language', client.getLanguage());
  fingerprint.append('nav_language_system', client.getSystemLanguage());
  fingerprint.append('nav_canvas', client.getCanvasPrint());
  try {
    fingerprint.append('nav_java_version', client.getJavaVersion());
    fingerprint.append('nav_flash_version', client.getFlashVersion());
  } catch (err) {
    return fingerprint;
  }
  return fingerprint;
};

export default getFingerPrint;
