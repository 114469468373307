import React from 'react';
import { useTranslation } from 'react-i18next';

import inbox from 'assets/inbox.png';

import styles from './AccessFormRequestSent.module.css';

export default function AccessFormRequestSent() {
  const { t } = useTranslation();
  return (
    <section className={styles.container}>
      <img src={inbox} width="50" alt="request sent" />
      <div className={styles.text}>
        <h1 className={styles.title}>
          {t('project_access.request_sent')}
        </h1>
        <p className={styles.description}>
          {t('project_access.request_approved_procedure')}
        </p>
      </div>
    </section>
  );
}
