import React, {
  useRef, useEffect, useState, MutableRefObject,
} from 'react';
import debounce from 'lodash.debounce';

export default function useVisibility(offset: number, wait = 0):
[
  boolean,
  MutableRefObject<HTMLElement | undefined>,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = useRef<HTMLDivElement | undefined>(undefined);

  const handleVisibility = () => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }
    const { top } = (currentElement.current as HTMLElement).getBoundingClientRect();
    if (top - 320 < 0) {
      setIsVisible(true);
    } else {
      setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
    }
  };

  const onScroll = debounce(handleVisibility, wait);

  const onTouchStart = (e: React.TouchEvent) => {
    // @ts-ignore
    window.touchPos = e.changedTouches[0].clientY;
  };

  const onTouchMove = (e: React.TouchEvent) => {
    const newTouchPos = e.changedTouches[0].clientY;
    if (currentElement.current) {
      const { top } = (currentElement.current as HTMLElement).getBoundingClientRect();
      // @ts-ignore
      if (newTouchPos > window.touchPos) {
        window.scrollTo(0, window.scrollY - top - 10);
        setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    // @ts-ignore
    document.addEventListener('touchstart', onTouchStart, true);
    // @ts-ignore
    document.addEventListener('touchmove', onTouchMove, true);
    return () => {
      document.removeEventListener('scroll', onScroll, true);
      // @ts-ignore
      document.removeEventListener('touchstart', onTouchStart, true);
      // @ts-ignore
      document.removeEventListener('touchmove', onTouchMove, true);
    };
  }, []);

  return [isVisible, currentElement, setIsVisible];
}
