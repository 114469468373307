import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';

import profilePicture from 'assets/base_profil_gray.png';
import Button from 'components/library/Button';
import { ProjectContext } from 'context/project';
import { Project, ProjectRecommandation } from 'types/project';

import styles from './ProjectRecommendation.module.css';
import ProjectRecommendationModal from './ProjectRecommendationModal';

interface ProjectRecommendationProps {
  project: Project,
  updateProject: (project: Project) => void,
}

export default function ProjectRecommendation({
  project, updateProject,
}: ProjectRecommendationProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const { isAdmin, mode } = useContext(ProjectContext);

  const handleOpenPopUp = () => setIsOpen((state) => !state);
  const handleSubmit = async (data: ProjectRecommandation) => {
    const newProject = {
      ...project,
      recommandation: [
        ...project.recommandation,
        data,
      ],
    };

    updateProject(newProject);
    handleOpenPopUp();
  };

  const handleDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const newProject = {
      ...project,
      recommandation: project.recommandation.filter((curr) => (
        curr.name !== (e.target as HTMLButtonElement).dataset.id
      )),
    };

    updateProject(newProject);
  };

  return (
    <section className={styles.recommendation}>
      {
        (project.recommandation?.length > 0)
        && (
          <div className={styles.list}>
            {
              project.recommandation.map((recommandation) => (
                <div className={styles.item}>
                  <img
                    src={recommandation.profilePicture || profilePicture}
                    alt={recommandation.name}
                    className={styles.picture}
                  />
                  <div className={styles.content}>
                    {
                      isAdmin && (mode === 'edition')
                      && (
                        <Button
                          variant="ghost"
                          type="custom"
                          className={styles.delete}
                          id={recommandation.name}
                          onClick={handleDelete}
                        >
                          <Delete />
                          {t('edition.founder.delete')}
                        </Button>
                      )
                    }
                    <p className={styles.name}>{recommandation.name}</p>
                    {
                      (recommandation.title)
                      && (<p className={styles.jobTitle}>{recommandation.title}</p>)
                    }
                    <p className={styles.description}>{recommandation.description}</p>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
      <Button
        onClick={handleOpenPopUp}
      >
        {t('presentation.ctaReco')}
      </Button>
      {
        (isOpen)
        && (
          <ProjectRecommendationModal
            close={handleOpenPopUp}
            onSubmit={handleSubmit}
          />
        )
      }
    </section>
  );
}
