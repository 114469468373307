import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckCircle, Edit, Error } from '@mui/icons-material';

import getErrorMessage from 'utils/error';
import { addToast } from 'services/store/features/toasts/actions';
import { uploadImageById } from 'services/network/api/project';
import * as statuses from 'services/store/states';

import Button from '../library/Button';
import styles from './InputFile.module.css';

interface InputFileProps {
  projectId: number,
  name: string,
  path: string,
  setPath: (path: string) => void,
}

export default function InputFile({
  projectId, name, path, setPath,
}: InputFileProps) {
  const [status, setStatus] = useState(statuses.IDLE);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(statuses.LOADING);
    const files = ((e.target as HTMLInputElement)?.files);
    const file = (files && files.length > 0) ? files[0] : undefined;
    if (file) {
      try {
        const { data } = await uploadImageById(projectId, file.name, file);
        setPath(data.data);
        setStatus(statuses.SUCCESS);
      } catch (err) {
        setStatus(statuses.FAIL);
        dispatch(addToast({
          type: 'error',
          title: t('edit.file.error'),
          description: getErrorMessage(err),
        }));
      }
    }
    window.setTimeout(() => setStatus(statuses.IDLE), 5000);
  };

  const resetFile = () => {
    setPath('');
  };

  return (
    <>
      {
        (path && (path !== ''))
          ? (
            <>
              <img
                src={path}
                alt={name}
                className={styles.img}
              />
              {
                (status === statuses.IDLE)
                && (
                  <Button
                    onClick={resetFile}
                    variant="ghost"
                    className={styles.reset}
                  >
                    <Edit className={styles.icon} />
                    {t('edit.file.update')}
                  </Button>
                )
              }
            </>
          )
          : (
            <input
              id={name}
              name={name}
              type="file"
              onChange={handleFileUpload}
            />
          )
      }
      {
        (status === statuses.LOADING)
        && (
          <p className={styles.loading}>
            {`${t('edit.file.loading')}...`}
          </p>
        )
      }
      {
        (status === statuses.SUCCESS)
        && (
          <p className={styles.success}>
            <CheckCircle className={styles.icon} />
            {`${t('edit.file.success')}`}
          </p>
        )
      }
      {
        (status === statuses.FAIL)
        && (
          <p className={styles.fail}>
            <Error className={styles.icon} />
            {`${t('edit.file.fail')}`}
          </p>
        )
      }
    </>
  );
}
