import React, {
  useContext, useEffect, useState, useRef, Suspense,
} from 'react';

import { ProjectContentSection as ProjectContentSectionInterface } from 'types/project';
import trackuser from 'utils/trackuser';
import isIE from 'utils/isIE';
import { ProjectContext } from 'context/project';

import Editor from './ProjectContentSectionEdition';
import styles from './ProjectContentSection.module.css';
import ProjectContentLayout from '../ProjectContentLayout';

let isFirstTime = true;

interface ProjectContentSectionProps {
  section: string,
  data: ProjectContentSectionInterface,
  onChange: (section: string, value: ProjectContentSectionInterface) => void,
  children?: React.ReactNode,
}

const getObserver = (setIsVisible: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (
    'IntersectionObserver' in window
    && 'isIntersecting' in window.IntersectionObserverEntry.prototype
  ) {
    return new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
    );
  }
  return undefined;
};

export default function ProjectContentSection({
  section, data, children, onChange,
}: ProjectContentSectionProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { isAdmin, mode, lang } = useContext(ProjectContext);
  const currentElement = useRef();

  const observer = getObserver(setIsVisible);

  useEffect(() => {
    if (observer && currentElement.current && section === 'market') {
      observer.observe(currentElement.current);
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect(); };
    }
    return undefined;
  }, []);

  useEffect(() => {
    if (isVisible && isFirstTime) {
      trackuser(window.projectName, 134, 'Scroll 50% +');
      isFirstTime = false;
    }
  }, [isVisible]);

  const getLanguage = () => lang;

  // TODO: remplacer les any
  const handleEditorChange = (event:any, editor: any) => {
    const currLang = getLanguage();
    if (editor.getData() !== '') onChange(section, { ...data, [currLang]: editor.getData() });
    else onChange(section, { ...data, [currLang]: ((data[currLang as 'fr' | 'en'] || '').length > 7) ? data[currLang as 'fr' | 'en'] : '<p></p>' });
  };

  const handleDisplay = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = { ...data, isDisplay: (e.target as HTMLInputElement).checked };
    onChange(section, newValue);
  };
  /* @ts-ignore */
  if (mode === 'preview' && (data[getLanguage()] === '')) return <div ref={currentElement} />;

  return (
    <ProjectContentLayout
      section={section}
      isDisplay={data.isDisplay}
      onDisplayChange={handleDisplay}
    >
      {
        (isAdmin && mode === 'edition' && !(isIE() && isIE() <= 9))
          ? (
            <Suspense fallback={<div>Loading...</div>}>
              <Editor data={data[getLanguage()] || ''} onChange={handleEditorChange} />
            </Suspense>
          )
          : (
            data.isDisplay
            && (
              <>
                { children }
                { /* @ts-ignore */ }
                <div ref={currentElement} className={styles.editor} dangerouslySetInnerHTML={{ __html: data[getLanguage()] || '' }} />
              </>
            )
          )
      }
    </ProjectContentLayout>
  );
}
