import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'services/store/store';

import App from './App';

import './i18n';
import './index.css';
import isIE from './utils/isIE';

if (process.env.NODE_ENV === 'development' && !isIE()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>,
  );
}
