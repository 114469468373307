import React from 'react';

import { sendMessage } from 'services/network/api/telegram';

class ErrorBoundary extends React.Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    sendMessage(
      `*[RENDER ERROR - ${new Date().toLocaleString()}]:* %0A URL: ${window.location.href} %0A Description: ${error}`,
    );
    return { hasError: true };
  }

  render() {
    // @ts-ignore
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    // @ts-ignore
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundary;
