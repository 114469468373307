import React, {
  useContext,
  useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';
import trackUser from 'utils/trackuser';
import { ProjectContext } from 'context/project';

import WhatsApp from '../ProjectSimulation/ProjectSimulationContent/WhatsAppButtonGreenLarge.svg';
import styles from './ProjectNavigation.module.css';

interface Props {
  children: (section: string) => React.ReactElement,
}

export default function ProjectNavigation({ children }: Props) {
  const [section, setSection] = useState('PROJECT');
  const [isActive, setActive] = useState(0);
  const [scrollTo, setScrollTo] = useState('');

  const { t } = useTranslation();
  const scrollIndicator = useRef(null);
  const { name } = useContext(ProjectContext);

  useEffect(() => {
    if (section === 'PROJECT' && scrollTo !== '') {
      document.getElementById(scrollTo)?.scrollIntoView({ behavior: 'smooth' });
      setScrollTo('');
    }
  }, [scrollTo, section]);

  const handleScrollIndicator = () => {
    if (scrollIndicator.current) {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      (scrollIndicator.current as HTMLElement).style.width = `${scrolled}%`;
    }
  };

  const setActiveClass = () => {
    const refs = [
      document.getElementById('project'),
      document.getElementById('team'),
      document.getElementById('finance'),
    ];
    const newOffset = refs.map((ref) => {
      if (ref !== null) {
        return ref.offsetTop + window.innerHeight - window.pageYOffset;
      }
      return ref;
    });
    const filteredOffset = newOffset.filter((el) => el && (el <= window.innerHeight + 50));
    if (filteredOffset.length === 0) setActive(0);
    if (filteredOffset.length === 1) {
      setActive(newOffset.indexOf(filteredOffset[0]) + 1);
    } else {
      const absOffset = filteredOffset.map((el) => Math.abs(el || 0));
      const sortedOffset = absOffset.sort((a, b) => a - b);
      setActive(newOffset.indexOf(sortedOffset[0]) + 1);
    }
  };

  const scrollToProject = () => {
    setSection('PROJECT');
    setScrollTo('section-project');
  };
  const scrollToTeam = () => {
    setSection('PROJECT');
    setScrollTo('section-team');
    trackUser(name, 140, 'Clic on team');
  };
  const scrollToFinance = () => {
    setSection('PROJECT');
    setScrollTo('section-finance');
    trackUser(name, 141, 'Clic on finance');
  };
  const openQuestions = () => {
    setSection('QUESTIONS');
    trackUser(name, 142, 'Clic on faq');
  };
  const openComments = () => {
    setSection('COMMENTS');
    trackUser(name, 143, 'Clic on comments');
  };

  const trackWhatsAppEvent = () => {
    trackUser(name, 145, 'Clic on whatsapp');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollIndicator);
    window.addEventListener('scroll', setActiveClass);
    return () => {
      window.removeEventListener('scroll', handleScrollIndicator);
      window.removeEventListener('scroll', setActiveClass);
    };
  }, []);

  return (
    <>
      <nav className={`${styles.navigation} ${(isActive > 0) ? styles.active : ''}`}>
        <div className={styles.content}>
          <div className={styles['link-group']}>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(isActive === 1) ? styles.active : ''} ${styles['hide-desktop']}`}
              onClick={scrollToFinance}
            >
              {t('navigation.document')}
            </Button>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(isActive === 1) ? styles.active : ''} ${styles['hide-mobile']}`}
              onClick={scrollToProject}
            >
              {t('navigation.company')}
            </Button>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(isActive === 2) ? styles.active : ''}`}
              onClick={scrollToTeam}
            >
              {t('navigation.team')}
            </Button>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(isActive === 3) ? styles.active : ''} `}
              onClick={scrollToFinance}
            >
              {t('navigation.finance')}
            </Button>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(section === 'QUESTIONS') ? styles.active : ''} ${styles['hide-mobile']}`}
              onClick={openQuestions}
            >
              { t('navigation.questions') }
            </Button>
            <Button
              type="custom"
              variant="ghost"
              className={`${styles.link} ${(section === 'COMMENTS') ? styles.active : ''} ${styles['hide-mobile']}`}
              onClick={openComments}
            >
              { t('navigation.comments') }
            </Button>
          </div>
          <div
            className={`${styles['phone-group']}`}
          >
            <a
              aria-label="Chat on WhatsApp"
              href="https://wa.me/33755540446"
              target="_blank"
              rel="noreferrer"
              onClick={trackWhatsAppEvent}
            >
              <img alt="Chat on WhatsApp" src={WhatsApp} />
            </a>
          </div>
        </div>
        <div className={styles['progress-container']}>
          <div
            className={styles['progress-bar']}
            ref={scrollIndicator}
          />
        </div>
      </nav>
      { children(section) }
    </>
  );
}
