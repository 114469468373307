import React, { useState } from 'react';

interface UseInvestProps {
  minimum: number,
  maximum?: number,
  valuation: number,
  sharePrice: number,
}

export default function useInvest({
  minimum, maximum, valuation, sharePrice,
}: UseInvestProps) {
  const [type, setType] = useState('text');
  const [input, setInput] = useState(`${minimum}€`);
  const [invest, setInvest] = useState(minimum);

  const handleSimulation = (value: number, callback?: (value: string) => void) => {
    if (value < minimum) {
      setInvest(minimum);
      if (callback) callback(`${minimum.toLocaleString()}€`);
      return;
    }
    if (value > (maximum || valuation)) {
      setInvest((maximum || valuation));
      if (callback) callback(`${(maximum || valuation).toLocaleString()}€`);
      return;
    }
    const result = Math.floor(value / sharePrice);
    if (result !== (value / sharePrice)) {
      const newInvest = (result + 1) * sharePrice;
      setInvest(newInvest);
      if (callback) callback(`${newInvest.toLocaleString()}€`);
      return;
    }
    setInvest(value);
    if (callback) callback(`${value.toLocaleString()}€`);
  };

  const handleInvest = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    handleSimulation(Number.parseFloat(e.target.value));
  };

  const handleShowInvest = () => {
    if (!Number.isNaN(invest)) {
      setInput(`${invest.toLocaleString()}€`);
    } else {
      setInvest(minimum);
      setInput(`${minimum.toLocaleString()}€`);
    }
    setType('text');
  };

  const handleClick = () => {
    setInput((curr) => (curr.replace('€', '')).replace(',', '.'));
    setType('number');
  };

  return {
    invest,
    input,
    type,
    setInput,
    handleClick,
    handleInvest,
    handleShowInvest,
    handleSimulation,
  };
}
