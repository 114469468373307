import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import SimulationInputRange from 'components/SimulationInputRange';
import InvestButton from 'components/InvestButton';
import trackUser from 'utils/trackuser';
import { ProjectUser } from 'types/project';
import useInvest from 'hooks/useInvest';

import arrow from 'assets/arrow.png';
import styles from './ProjectHeaderSimulation.module.css';
import Button from '../../../../../components/library/Button';

interface ProjectHeaderSimulationProps {
  name: string,
  id: string,
  calendarLink: string,
  minimum: number,
  maximum?: number,
  valuation: number,
  sharePrice: number,
  rate?: number,
  user?: ProjectUser,
}

export default function ProjectHeaderSimulation({
  name, id, user, minimum, maximum, valuation, sharePrice, rate,
  calendarLink,
}: ProjectHeaderSimulationProps) {
  const { t } = useTranslation();
  const {
    input, invest, type,
    setInput, handleClick,
    handleInvest, handleShowInvest, handleSimulation,
  } = useInvest({
    minimum, maximum, valuation, sharePrice,
  });

  const debouncedTrack = useCallback(
    debounce((nextInvest) => trackUser(name, 50, `Montant testé ${nextInvest}`), 1000),
    [],
  );

  useEffect(() => {
    if (invest !== minimum) {
      debouncedTrack(invest);
    }
  }, [invest]);

  const handleRangeChange = (value: number) => handleSimulation(value, setInput);

  const handleCalendarClick = () => {
    window.open(calendarLink, '_blank');
    trackUser(name, 123, 'Prendre un rendez-vous');
  };

  return (
    <div className={styles.container}>
      <section className={styles.simulation}>
        <p className={styles.title}>
          {t('header.simulation')}
        </p>
        <div className={styles.inputContainer}>
          <input
            type={type}
            className={styles.input}
            name="header-simulation-input"
            value={input}
            aria-label={t('header.simulation.label')}
            onChange={handleInvest}
            onBlur={handleShowInvest}
            onClick={handleClick}
          />
        </div>
        <SimulationInputRange
          minimum={minimum}
          maximum={maximum}
          valuation={valuation}
          invest={invest}
          onChange={handleRangeChange}
        />
        {
          (rate)
          && (
            <p className={styles.reduction}>
              <Trans
                i18nKey="simulation.reduction"
                values={{ value: t('currency', { value: ((rate * invest) / 100).toFixed(2) }) }}
              />
            </p>
          )
        }
        <InvestButton
          project={name}
          history={invest}
          userInfo={user}
          id={id}
        />
        <img
          src={arrow}
          alt="Click here"
          className={styles.arrow}
        />
        <Button
          type="primary"
          variant="outline"
          className={styles.link}
          onClick={handleCalendarClick}
        >
          {t('header.rdv')}
        </Button>
      </section>
    </div>
  );
}
