import React from 'react';

import styles from './ProjectHeaderSummaryValue.module.css';

interface ProjectHeaderSummaryValueProps {
  label: string,
  value: number | string,
  children?: React.ReactElement,
}

export default function ProjectHeaderSummaryValue(
  { label, value, children }: ProjectHeaderSummaryValueProps,
) {
  return (
    <div className={styles.container}>
      <p className={styles.label}>
        {label}
        {children}
      </p>
      <p className={styles.value}>
        {value}
      </p>
    </div>
  );
}
