import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/library/Input';
import Modal from 'components/Modal';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import { ProjectSimulation as ProjectSimulationType, ProjectSimulation } from 'types/project';

import styles from './ProjectSimulationForm.module.css';

interface ProjectSimulationFormProps {
  close: () => void,
  onSubmit: (newSimulation: ProjectSimulationType) => void,
  simulation: ProjectSimulation
}

export default function ProjectSimulationForm({
  simulation, onSubmit, close,
}: ProjectSimulationFormProps) {
  const [value, setValue] = useState(simulation);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => (
    setValue((curr: ProjectSimulation) => ({
      ...curr,
      [e.target.name]: e.target.value,
    })));

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => (
    setValue((curr: ProjectSimulation) => ({
      ...curr,
      [e.target.name]: e.target.checked,
    })));

  const submit = () => {
    const newSimulation = {
      ...value,
      minimum: Number.parseFloat(value.minimum.toString()),
      share: (value.isHolding) ? (value.unitPrice || 0) + (value.emissionPrice || 0) : value.share,
    };
    onSubmit(newSimulation);
    close();
  };

  const isValid = () => {
    const minimum = Number.parseFloat(value.minimum.toString());
    const share = Number.parseFloat(value.share.toString());
    return !(!Number.isNaN(minimum) && !Number.isNaN(share));
  };

  return (
    <Modal close={close}>
      <form className={styles.form}>
        <Input label={t('edition.simulation.share')} name="share">
          <InputText
            name="share"
            placeholder={t('edition.simulation.share')}
            value={value.share.toString() || ''}
            onChange={handleChange}
            disabled
          />
        </Input>
        <Input label={t('edition.simulation.minimum', { devise: '€' })} name="minimum">
          <InputText
            type="number"
            name="minimum"
            placeholder={t('edition.simulation.minimum')}
            value={value.minimum?.toString() || ''}
            onChange={handleChange}
            disabled
          />
        </Input>
        <label htmlFor="isHolding">
          <input
            type="checkbox"
            name="isHolding"
            checked={value.isHolding}
            onChange={onCheck}
            className={styles.checkbox}
          />
          {t('edition.simulation.holding')}
        </label>
        {
          (value.isHolding)
          && (
            <>
              <Input
                name="emissionPrice"
                label={t('edition.simulation.emissionPrice')}
              >
                <InputText
                  name="emissionPrice"
                  placeholder={t('edition.simulation.emissionPrice')}
                  value={value?.emissionPrice?.toString() || ''}
                  onChange={handleChange}
                  disabled
                />
              </Input>
              <Input
                name="unitPrice"
                label={t('edition.simulation.unitPrice')}
              >
                <InputText
                  name="unitPrice"
                  placeholder={t('edition.simulation.unitPrice')}
                  value={value?.unitPrice?.toString() || ''}
                  onChange={handleChange}
                  disabled
                />
              </Input>
            </>
          )
        }
        <Button
          onClick={submit}
          disabled={isValid()}
        >
          {t('edition.simulation.save')}
        </Button>
      </form>
    </Modal>
  );
}
