import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorRounded } from '@mui/icons-material';

import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import Spinner from 'components/Spinner';
import { ProjectUser } from 'types/project';

import styles from './ProjectContentPopUpVerification.module.css';

interface ProjectContentPopUpVerificationProps {
  isCodeValid: boolean,
  isLoading: boolean,
  onSubmit: ({ data, code }: { data?: ProjectUser, code?: string }) => void,
  onBack: () => void,
}

export default function ProjectContentPopUpVerification({
  isCodeValid, isLoading, onSubmit, onBack,
}: ProjectContentPopUpVerificationProps) {
  const [code, setCode] = useState('');
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value);
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit({ code });
  };

  return (
    <section className={styles.verification}>
      <h2 className={styles.title}>
        {t('presentation.restricted.smsSend')}
      </h2>
      <p className={styles.description}>
        {t('presentation.restricted.code')}
      </p>
      <Input
        label={t('presentation.restricted.receiveCode')}
        name="code"
      >
        <InputText
          name="code"
          placeholder={t('presentation.restricted.receiveCode')}
          value={code}
          onChange={handleChange}
        />
      </Input>
      {
        (!isCodeValid) && (
          <p className={styles.error}>
            <ErrorRounded />
            {t('presentation.restricted.codeError')}
          </p>
        )
      }
      <Button
        onClick={handleSubmit}
        className={styles.button}
        disabled={code === '' || isLoading}
      >
        {
          isLoading
            ? (
              <>
                <Spinner />
                <span className={styles.submitting}>
                  {t('project_access.submitting')}
                </span>
              </>
            )
            : t('presentation.restricted.button')
        }
      </Button>
      <Button
        variant="ghost"
        onClick={onBack}
        className={styles.button}
      >
        {t('project_access.back')}
      </Button>
    </section>
  );
}
