import * as types from './types';

import { Toast } from './factory';

export interface ToastState {
  list: Toast[];
  autoDeleteTime: number;
}

const initialState: ToastState = {
  list: [],
  autoDeleteTime: 10000,
};

export default function toastsReducer(
  state = initialState,
  { type, payload }: { type?:string, payload?: number | Toast } = {},
) {
  switch (type) {
    case types.ADD_TOAST:
      return { ...state, list: [...state.list, payload] };
    case types.REMOVE_TOAST:
      return { ...state, list: state.list.filter((item) => item.id !== payload) };
    default:
      return state;
  }
}
