import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import checkEmail from 'utils/checkEmail';

import styles from './ProjectManagerUserInterestMail.module.css';

interface ProjectManagerUserInterestMailProps {
  onSubmit: (email: string) => void,
}

export default function ProjectManagerUserInterestMail({
  onSubmit,
}: ProjectManagerUserInterestMailProps) {
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (checkEmail(email)) {
      onSubmit(email);
    } else {
      setHasError(true);
    }
  };

  return (
    <form>
      <p>
        {t('interest.email')}
      </p>
      <Input
        label={t('interest.emailInput')}
        name="email"
        state={(hasError) ? 'ERROR' : 'DEFAULT'}
      >
        <InputText
          placeholder={t('interest.emailInput')}
          name="email"
          value={email}
          onChange={handleChange}
        />
        {
          (hasError)
          && (
            <p className={styles.hint}>
              {t('interest.invalidMail')}
            </p>
          )
        }
      </Input>
      <Button
        className={styles.submit}
        onClick={handleSubmit}
        disabled={email === ''}
      >
        {t('interest.send')}
      </Button>
    </form>
  );
}
