import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';

import styles from './ProjectCTA.module.css';

interface ProjectCTAProps {
  calendar: string,
}

export default function ProjectCTA({ calendar }: ProjectCTAProps) {
  const openCalendar = () => window.open(calendar, '_blank');
  const { t } = useTranslation();
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <h1 className={styles.text}>
          {t('cta.questions')}
        </h1>
        <Button
          type="primary"
          className={styles.button}
          onClick={openCalendar}
        >
          {t('header.ctaCall')}
        </Button>
      </div>
    </div>
  );
}
