import { Dispatch } from 'redux';

import { toBackendProject } from 'services/network/adaptor/project';
import { updateProjectById } from 'services/network/api/project';
import { addToast } from 'services/store/features/toasts/actions';
import { Project } from 'types/project';

import getErrorMessage from './error';
import { sendMessage } from '../services/network/api/telegram';

export const getProjectId = () => {
  try {
    const url = document.URL.split('?')[0];
    const splitURL = url.split('/');
    // remove '' element
    splitURL.pop();
    // get the id
    const id = splitURL[splitURL.length - 1];
    return Number.parseInt(id, 10);
  } catch (err) {
    sendMessage(`[ERROR]: PROJECT ID NOT FOUND ${window.location.href}`);
  }
  return 0;
};

// TODO: remplacer les any

export const updateProjectManager = async (
  id: string,
  newProject: Project,
  dispatch: Dispatch<any>,
  t: any,
  callback?: () => void,
) => {
  try {
    const newFormattedProject = toBackendProject(newProject);
    const { data: response } = await updateProjectById(
      Number.parseInt(id, 10),
      newFormattedProject,
    );
    if (response.error === '') {
      dispatch(addToast({
        type: 'success',
        title: t('header.update.success'),
        description: '',
      }));
      if (callback) callback();
    }
  } catch (err) {
    dispatch(addToast({
      type: 'error',
      title: t('header.update.error'),
      description: getErrorMessage(err),
    }));
  }
};
