import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Delete, Download } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import Button from 'components/library/Button';
import getErrorMessage from 'utils/error';
import { addToast } from 'services/store/features/toasts/actions';
import { uploadImageById } from 'services/network/api/project';
import { getProjectId } from 'utils/project';
import { ProjectDocumentContent } from 'types/project';
import trackuser from 'utils/trackuser';
import { ProjectContext } from 'context/project';

import ProjectContentLayout from '../ProjectContentLayout';
import styles from './ProjectContentDocument.module.css';

interface ProjectContentDocumentProps {
  data: ProjectDocumentContent,
  onChange: (section: string, newDocuments: ProjectDocumentContent) => void,
}

export default function ProjectContentDocument({ data, onChange }: ProjectContentDocumentProps) {
  const dispatch = useDispatch();
  const {
    isAdmin, mode, name, lang,
  } = useContext(ProjectContext);
  const { t } = useTranslation();
  const inputFile = useRef(null);

  const getLanguage = () => lang;

  const handleDisplayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDocuments = { ...data, isDisplay: e.target.checked };
    onChange('documents', newDocuments);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    const newDocuments = {
      ...data,
      [getLanguage()]: (data[getLanguage()] || [])
        .filter((document) => document.file !== (e.target as HTMLButtonElement).dataset.id),
    };
    onChange('documents', newDocuments);
  };

  const handleFileOpen = () => {
    if (inputFile.current) (inputFile.current as HTMLInputElement).click();
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = ((e.target as HTMLInputElement)?.files);
    const file = (files && files.length > 0) ? files[0] : undefined;
    if (file) {
      try {
        const { data: dataFile } = await uploadImageById(getProjectId(), file.name, file);
        const newDocuments = {
          ...data,
          [getLanguage()]: [
            ...(data[getLanguage()] || []),
            { title: file.name, file: dataFile.data },
          ],
        };
        onChange('documents', newDocuments);
      } catch (err) {
        dispatch(addToast({
          type: 'error',
          title: 'document.uploadFailed',
          description: getErrorMessage(err),
        }));
      }
    }
  };

  return (
    <ProjectContentLayout
      section="documents"
      isDisplay={data.isDisplay}
      onDisplayChange={handleDisplayChange}
    >
      <div className={styles.list} id="documents">
        {
          (data[getLanguage()] || []).map((document) => (
            <div
              className={styles.document}
              key={document.file}
            >
              <p className={styles.title}>
                { document.title }
              </p>
              <a
                href={document.file}
                className={styles.link}
                onClick={() => trackuser(name, 74, 'Demande de téléchargement de document')}
                target="_blank"
                rel="noreferrer"
              >
                <Download />
                {t('edition.document.download')}
              </a>
              {
                (isAdmin && mode === 'edition')
                && (
                  <Button
                    id={document.file}
                    type="custom"
                    variant="ghost"
                    className={styles.delete}
                    onClick={handleDelete}
                  >
                    <Delete />
                    {t('edition.document.delete')}
                  </Button>
                )
              }
            </div>
          ))
        }
        {
          (isAdmin && mode === 'edition')
          && (
            <div className={styles.document}>
              <p className={styles.title}>
                {t('edition.document.add')}
              </p>
              <input
                type="file"
                ref={inputFile}
                className={styles.hide}
                onChange={handleUpload}
              />
              <Button
                onClick={handleFileOpen}
              >
                <Add />
                {t('edition.document.add')}
              </Button>
            </div>
          )
        }
      </div>
    </ProjectContentLayout>
  );
}
