import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@mui/icons-material';

import InputText from 'components/library/InputText';
import Input from 'components/library/Input';
import Button from 'components/library/Button';
import InputPhone from 'components/InputPhone';
import Spinner from 'components/Spinner';
import verifyEmail from 'utils/checkEmail';
import { ProjectUser } from 'types/project';

import styles from './ProjectContentPopUpRegister.module.css';

interface ProjectContentPopUpRegisterProps {
  isLoading: boolean,
  onSubmit: ({ data, code }: { data?: ProjectUser, code?: string }) => void,
  onChange: () => void,
  onClose: () => void,
}

export default function ProjectContentPopUpRegister({
  isLoading, onSubmit, onChange, onClose,
}: ProjectContentPopUpRegisterProps) {
  const [data, setData] = useState({
    lastName: '',
    firstName: '',
    email: new URLSearchParams(window.location.search).get('email') || '',
    phone: '',
  });
  const [error, setError] = useState<{
    email?: string, phone?: string,
  } | undefined>(undefined);
  const isButtonDisabled = useMemo(() => {
    const {
      email, phone,
    } = data;
    return (email === '' || phone === '');
  }, [data]);

  const { t } = useTranslation();

  const validateFields = () => {
    let newErrors;
    if (!data.email || !verifyEmail(data.email)) {
      newErrors = { ...(newErrors || {}), email: t('presentation.restricted.emailError') };
    }
    if (!data.phone || error?.phone) {
      newErrors = { ...(newErrors || {}), phone: error?.phone };
    }
    setError(newErrors);
    return newErrors;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
    onChange();
  };

  const handlePhone = (newPhone: string) => {
    setData((curr) => ({
      ...curr,
      phone: newPhone,
    }));
    onChange();
  };

  const handlePhoneError = (isValid: boolean) => {
    if (isValid) {
      setError((err) => ({ ...err, phone: undefined }));
    } else {
      setError((err) => ({ ...err, phone: t('presentation.restricted.phoneError') }));
    }
  };

  const handleSubmit = () => {
    const errors = validateFields();
    if (!errors) {
      onSubmit({ data });
    }
  };

  return (
    <section>
      <h2 className={styles.title}>
        {t('presentation.restricted.serious_investor_hint')}
      </h2>
      <p className={styles['investor-hint']}>
        {t('presentation.restricted.description')}
      </p>
      <form className={styles.form}>
        <Input
          label={t('presentation.restricted.mail')}
          name="email"
          state={(error?.email) ? 'ERROR' : 'DEFAULT'}
        >
          <InputText
            placeholder={t('presentation.restricted.mail')}
            name="email"
            value={data.email}
            onChange={handleChange}
          />
          {
            (error?.email)
            && (
              <p className={styles.hint}>
                <Error className={styles.icon} />
                <span>
                  { error?.email }
                </span>
              </p>
            )
          }
        </Input>
        <Input
          label={t('presentation.restricted.phone')}
          name="phone"
          state={(error?.phone) ? 'ERROR' : 'DEFAULT'}
        >
          <InputPhone
            placeholder={t('presentation.restricted.phone')}
            name="phone"
            value={data.phone}
            onChange={handlePhone}
            onError={handlePhoneError}
          />
          {
            (error?.phone)
            && (
              <p className={styles.hint}>
                <Error className={styles.icon} />
                <span>
                  { error?.phone }
                </span>
              </p>
            )
          }
        </Input>
      </form>
      <Button
        className={styles.button}
        onClick={handleSubmit}
        disabled={isButtonDisabled || isLoading}
      >
        {
          isLoading
            ? (
              <>
                <Spinner />
                <span className={styles.submitting}>
                  {t('project_access.submitting')}
                </span>
              </>
            )
            : t('presentation.restricted.button')
        }
      </Button>
      <Button
        variant="ghost"
        onClick={onClose}
        className={styles.back}
      >
        {t('presentation.restricted.back')}
      </Button>
    </section>
  );
}
