import React, { useEffect } from 'react';

import Toasts from 'components/library/Toasts';
import ErrorBoundary from 'components/library/DebugErrorBoundary';
import Project from 'views/Project';
import { openLinkInNewTab } from 'utils/events';

import 'components/library/style/normalize.css';
import 'components/library/style/reset.css';
import 'components/library/style/animation.css';
import 'components/library/style/typography.css';

import { launchTrackUser } from './utils/trackuser';

function App() {
  useEffect(() => {
    launchTrackUser(window.projectName);
    document.body.addEventListener('click', openLinkInNewTab);
    return () => document.body.removeEventListener('click', openLinkInNewTab);
  }, []);

  return (
    <ErrorBoundary>
      <Project />
      <Toasts />
    </ErrorBoundary>
  );
}

export default App;
