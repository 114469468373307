import React from 'react';

import styles from './ToggleSwitch.module.css';

interface ToggleSwitchProps {
  name: string,
  checked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function ToggleSwitch({ name, checked, onChange }: ToggleSwitchProps) {
  return (
    <label className={styles.switch} htmlFor={name}>
      <input
        type="checkbox"
        id={name}
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.slider} />
    </label>
  );
}
