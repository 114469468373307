import axios from 'axios';
import axiosRetry from 'axios-retry';

import domains from '../../global';
import { getCookie } from '../../utils/trackuser';

const axiosMockClient = axios.create({
  baseURL: domains.url,
  timeout: 120000,
});

export const axiosAuthClient = axios.create({
  baseURL: `${domains.auth}/api`,
  timeout: 60000,
});

const cookie = getCookie('csrftoken');
if (cookie) {
  axiosMockClient.defaults.headers.put['X-CSRFToken'] = cookie;
  axiosMockClient.defaults.headers.post['X-CSRFToken'] = cookie;
}

axiosRetry(axiosMockClient, { retries: 3 });

export default axiosMockClient;
