const isLocalStorageSupported = () => {
  const key = 'ayomi';
  try {
    window.localStorage.getItem(key);
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};

export default isLocalStorageSupported;
