import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorAnimation from '../../../components/library/ErrorAnimation';
import Modal from '../../../components/Modal';

import styles from './ProjectClosed.module.css';
import { getProjectId } from '../../../utils/project';

export default function ProjectClosed() {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  });

  if (getProjectId() === 15174) {
    return (
      <Modal>
        <>
          <h1 className={styles.end}>
            {t('project.closed.title')}
          </h1>
          <p className={styles['end-description']}>
            Super nouvelle !
            <br />
            Le lancement de campagne de financement a généré une traction inattendue,
            nous sommes clairement sous-staffer
            pour poursuivre; nous mettons cette levée de fonds entre parenthèse !
            <br />
            Nous vous reviendrons très vite
            Vous pourrez tous être prochainement actionnaires.
            Merci aux premiers souscripteurs !
          </p>
        </>
      </Modal>
    );
  }

  return (
    <Modal>
      <>
        <ErrorAnimation />
        <h1 className={styles.title}>
          {t('project.closed.title')}
        </h1>
        <p className={styles.description}>
          {t('project.closed.description')}
        </p>
      </>
    </Modal>
  );
}
