import React from 'react';
import { useTranslation } from 'react-i18next';

import creditError from 'assets/creditError.png';
import Button from 'components/library/Button';
import { trackWhatsAppEvent } from 'utils/logs';

import WhatsApp from '../../../ProjectSimulation/ProjectSimulationContent/WhatsAppButtonGreenLarge.svg';
import styles from '../ProjectContentPopUp.module.css';

interface Props {
  onClose: () => void;
}

export default function ProjectContentPopUpCreditError({ onClose }: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles['credit-error']}>
      <img
        src={creditError}
        alt="credit error"
        width={44}
      />
      <div>
        <p className={styles.title}>
          {t('presentation.restricted.registration.error_credit')}
        </p>
        <p className={styles.description}>
          {t('presentation.restricted.registration.error_credit_description')}
        </p>
      </div>
      <div className={styles['button-group']}>
        <div
          className={styles.whatsapp}
        >
          <a
            aria-label="Chat on WhatsApp"
            href="https://wa.me/33755540446"
            target="_blank"
            rel="noreferrer"
            onClick={trackWhatsAppEvent}
          >
            <img alt="Chat on WhatsApp" src={WhatsApp} />
          </a>
        </div>
        <Button
          variant="ghost"
          onClick={onClose}
          className={styles.button}
        >
          {t('presentation.restricted.back')}
        </Button>
      </div>
    </div>
  );
}
