//  TODO: refactor

export const displayNumber = (str) => {
  // if (isNaN(str)) return 1;
  if (str === undefined) return 0;
  const strToArray = str.toString().split('').filter((i) => i !== '€');
  if (strToArray.length === 0) return 0;
  if (strToArray.length < 4) {
    return strToArray.join('');
  }
  const end = [];
  // Add space every 3 elements
  if (
    Number.isNaN(strToArray[strToArray.length - 1])
    && Number.isNaN(parseFloat(strToArray[strToArray.length - 1]))
  ) {
    end.push(strToArray.pop());
  }
  if (strToArray.includes('.')) {
    const floatIndex = strToArray.indexOf('.');
    const float = [];
    for (let i = floatIndex; i < strToArray.length; i += 1) {
      float.push(strToArray[i]);
    }
    end.unshift(float.join(''));
    strToArray.splice(floatIndex, strToArray.length - floatIndex);
  }
  let spliceIndex = strToArray.length;
  while (spliceIndex > 3) {
    spliceIndex -= 3;
    strToArray.splice(spliceIndex, 0, ' ');
  }
  strToArray.push(end.join(''));
  return strToArray.join('');
};

const numberFilter = (i) => (i !== '€') && (i !== ' ') && (i !== ',');

export const getNumber = (str) => {
  if (str) {
    const strToArray = str.toString().split('').filter(numberFilter);
    if (!Number.isNaN(parseFloat(strToArray.join('')))) {
      return parseFloat(strToArray.join(''));
    }
  }
  return 0;
};
