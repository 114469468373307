import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import InputFile from 'components/InputFile';
import Button from 'components/library/Button';
import Modal from 'components/Modal';
import { getProjectId } from 'utils/project';
import { ProjectRecommandation } from 'types/project';

import styles from '../ProjectRecommendation.module.css';

interface ProjectRecommendationModalProps {
  close: () => void,
  onSubmit: (data: ProjectRecommandation) => void,
}

export default function ProjectRecommendationModal({
  onSubmit, close,
}: ProjectRecommendationModalProps) {
  const [data, setData] = useState({
    name: '',
    title: '',
    description: '',
    profilePicture: '',
  });
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setData((state) => ({
    ...state,
    [e.target.name]: e.target.value,
  }));
  const handleFile = (path: string) => setData((state) => ({
    ...state,
    profilePicture: path,
  }));
  const handleSubmit = () => onSubmit(data);

  return (
    <Modal close={close}>
      <p className={styles.title}>
        {t('edition.recommend.add')}
      </p>
      <form className={styles.form}>
        <Input
          label={t('edition.recommend.name')}
          name="name"
        >
          <InputText
            placeholder={t('edition.recommend.name')}
            name="name"
            value={data.name}
            onChange={handleChange}
          />
        </Input>
        <Input
          label={t('edition.recommend.title')}
          name="title"
        >
          <InputText
            placeholder={t('edition.recommend.title')}
            onChange={handleChange}
            value={data.title}
            name="title"
          />
        </Input>
        <Input
          label={t('edition.recommend.description')}
          name="description"
        >
          <InputText
            placeholder={t('edition.recommend.description')}
            onChange={handleChange}
            value={data.description}
            name="description"
          />
        </Input>
        <Input
          label={t('edition.recommend.profilePicture')}
          name="profilePicture"
          containerClassName={styles.file}
        >
          <InputFile
            projectId={getProjectId()}
            name="profilePicture"
            path={data.profilePicture}
            setPath={handleFile}
          />
        </Input>
        <div className={styles.footer}>
          <Button
            onClick={close}
            variant="ghost"
          >
            {t('modal.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={data?.name === '' || data?.description === ''}
          >
            {t('edition.recommend.add')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
