import React from 'react';

import * as statuses from 'services/store/states';

interface LoadingProps {
  status: string,
  fallback?: React.ReactElement,
  errorFallback?: React.ReactElement,
  children: React.ReactElement,
}

export default function Loading({
  status, fallback, errorFallback, children,
}: LoadingProps) {
  if (status === statuses.SUCCESS && children) {
    return (
      <div className="fadeIn">
        { children }
      </div>
    );
  }
  if (status === statuses.LOADING) {
    if (fallback) {
      return fallback;
    }
    return <p>Loading...</p>;
  }

  if (status === statuses.FAIL) {
    if (errorFallback) {
      return errorFallback;
    }
  }

  return null;
}
