import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import Input from 'components/library/Input';
import InputText from 'components/library/InputText';
import Button from 'components/library/Button';
import InputFile from 'components/InputFile';
import { Project } from 'types/project';
import { ProjectContext } from 'context/project';

import styles from './ProjectHeaderEdition.module.css';

interface ProjectHeaderEditionProps {
  project: Project,
  updateProject: (newValue: Project) => void,
  close: () => void;
}

export default function ProjectHeaderEdition({
  project, updateProject, close,
}: ProjectHeaderEditionProps) {
  const [data, setData] = useState(project.content.header);
  const { t } = useTranslation();
  const { lang } = useContext(ProjectContext);

  const getTagsValue = (): string => ((Array.isArray(data[lang]?.tags)
    ? data[lang]?.tags.join(', ')
    : (`${data[lang]?.tags}` || '')) || '');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => (
    setData((curr) => ({ ...curr, [e.target.name]: e.target.value })));

  const handleChangeByLang = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (
    setData((curr) => ({
      ...curr,
      [lang]: {
        ...curr[lang],
        [e.target.name]: e.target.value,
      },
    }))
  );

  const handleLogoChange = (path: string) => {
    setData((curr) => ({
      ...curr,
      logo: path,
    }));
  };

  const handleCoverChange = (cover: string) => {
    setData((curr) => ({
      ...curr,
      picture: cover,
    }));
  };

  const submit = async () => {
    const newProject = {
      ...project,
      content: {
        ...project.content,
        header: {
          ...project.content.header,
          ...data,
          en: {
            ...project.content.header.en,
            ...data,
            description: data.en?.description || '',
            title: data.en?.title || '',
            tags: Array.isArray(data.en?.tags || [])
              ? (data.en?.tags || [])
              : `${data.en?.tags}`.split(', '),
          },
          fr: {
            ...project.content.header.fr,
            ...data,
            description: data.fr?.description || '',
            title: data.fr?.title || '',
            tags: Array.isArray(data.fr?.tags || [])
              ? (data.fr?.tags || [])
              : `${data.fr?.tags}`.split(', '),
          },
        },
      },
    };
    updateProject(newProject);
    close();

    /* const callback = () => {
      updateProject(newProject);
      close();
    };
    await updateProjectManager(newProject.id, newProject, dispatch, t, callback); */
  };

  return (
    <Modal close={close}>
      <h1 className={styles.title}>{t('header.edit')}</h1>
      <Input
        label={t('header.projectName')}
        name="name"
        containerClassName={styles.label}
      >
        <InputText
          placeholder={t('header.projectName')}
          name="name"
          value={data.name}
          onChange={handleChange}
        />
      </Input>
      <Input
        label={t('header.title')}
        name="title"
        containerClassName={styles.label}
      >
        <InputText
          name="title"
          placeholder={t('header.title')}
          value={data[lang]?.title}
          onChange={handleChangeByLang}
        />
      </Input>
      <Input
        label={t('header.description')}
        name="description"
        containerClassName={styles.label}
      >
        <textarea
          className={styles.input}
          name="description"
          value={data[lang]?.description}
          onChange={handleChangeByLang}
        />
      </Input>
      <Input
        label={t('header.city')}
        name="city"
        containerClassName={styles.label}
      >
        <InputText
          className={styles.input}
          placeholder={t('header.city')}
          name="city"
          value={data.city}
          onChange={handleChange}
        />
      </Input>
      <Input
        label={t('header.tags')}
        name="tags"
        containerClassName={styles.label}
      >
        <p className={styles.hint}>
          Séparer les tags par une virgules
        </p>
        <InputText
          placeholder={t('header.tags')}
          name="tags"
          value={getTagsValue()}
          onChange={handleChangeByLang}
        />
      </Input>
      <Input
        label={t('header.funds')}
        name="funds"
        containerClassName={styles.label}
      >
        <InputText
          placeholder={t('header.funds')}
          name="funds"
          value={data.funds}
          onChange={handleChange}
        />
      </Input>
      <Input
        label={t('header.valuation')}
        name="valuation"
        containerClassName={styles.label}
      >
        <InputText
          name="valuation"
          placeholder={t('header.valuation')}
          value={data.valuation}
          onChange={handleChange}
        />
      </Input>
      <Input
        label={t('header.shares')}
        name="share"
        containerClassName={styles.label}
      >
        <InputText
          placeholder={t('header.shares')}
          name="share"
          value={data.share}
          onChange={handleChange}
          disabled
        />
      </Input>
      <div className={styles.rowFile}>
        <Input
          label={t('header.file.logo')}
          name="logo"
          containerClassName={`${styles.label} ${styles.img}`}
        >
          <InputFile
            projectId={Number.parseInt(project.id, 10)}
            name="logo"
            path={data.logo}
            setPath={handleLogoChange}
          />
        </Input>
        <Input
          label={t('header.file.cover')}
          name="Cover"
          containerClassName={`${styles.label} ${styles.img}`}
        >
          <InputFile
            projectId={Number.parseInt(project.id, 10)}
            name="cover"
            path={data.picture}
            setPath={handleCoverChange}
          />
        </Input>
      </div>
      <Button
        className={styles.save}
        onClick={submit}
      >
        {t('edit.save')}
      </Button>
    </Modal>
  );
}
