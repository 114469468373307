import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorOutlined } from '@mui/icons-material';

import AuthorizationContext from 'utils/AuthorizationContext';

import styles from './PermissionDenied.module.css';
import Button from '../library/Button/Button';
import AskProjectAccessForm from '../AskProjectAccess/AskProjectAccessForm';

interface Props {
  phone: string,
  calendar: string,
}

export default function PermissionDenied({ phone, calendar }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [hideLogIn, setHideLogIn] = useState(false);
  const { t } = useTranslation();
  const { isAuthorized } = useContext(AuthorizationContext);

  useEffect(() => {
    document.body.classList.add('disable-scroll');
  }, []);

  const goAuth = () => {
    let { host } = window.location;
    const firstPoint = host.indexOf('.');
    if (firstPoint !== -1) {
      host = `${host.slice(0, firstPoint)}-auth${host.slice(firstPoint, host.length)}`;
      const url = `https://${host}/?redirect=${window.location.href}`;
      window.location.assign(url);
    } else if (window.projectName && window.projectName.trim() !== '' && window.projectName !== '{{title}}') {
      window.location.assign(`https://${window.projectName}-auth.lendeers.com/?redirect=${window.location.href}`);
    } else {
      setHideLogIn(true);
    }
  };

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  if (isAuthorized) return null;

  return (
    <>
      <div className={`${styles.background}`}>
        <section className={`bounceIn ${styles.modal}`}>
          <div className={styles.illustration}>
            <ErrorOutlined className={styles.icon} />
          </div>
          <h1 className={styles.title}>
            {t('permission.title')}
          </h1>
          {
            (!hideLogIn)
              ? (
                <div className={styles['button-group']}>
                  <Button
                    onClick={openModal}
                  >
                    {t('navigation.create_account')}
                  </Button>
                  <Button
                    variant="ghost"
                    className={styles.button}
                    onClick={goAuth}
                  >
                    {t('permission.button')}
                  </Button>
                </div>
              )
              : (
                <>
                  <p className={styles.description}>
                    {t('permission.loginUrl')}
                  </p>
                  <Button
                    onClick={openModal}
                  >
                    {t('navigation.create_account')}
                  </Button>
                </>
              )
          }
        </section>
      </div>
      {
        (isOpen)
        && (
          <AskProjectAccessForm
            phone={phone}
            calendar={calendar}
            onClose={closeModal}
          />
        )
      }
    </>
  );
}
