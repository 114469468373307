import React from 'react';

import styles from './SimulationInputRange.module.css';

interface SimulationInputRangeProps {
  invest: number,
  minimum: number,
  maximum?: number,
  valuation: number,
  onChange: (newValue: number) => void,
}

export default function SimulationInputRange({
  invest, minimum, maximum, valuation, onChange,
}: SimulationInputRangeProps) {
  const MAX_RANGE_POSITION = 100;

  const minRangeValue = Math.log(minimum);
  const maxRangeValue = Math.log(maximum || valuation);
  const scale = (maxRangeValue - minRangeValue) / MAX_RANGE_POSITION;

  const getRangePositionByValue = () => (Math.log(invest) - minRangeValue) / scale;
  const getValueByRangePosition = (position: number) => Math.exp(minRangeValue + scale * position);

  const handleRangeInvest = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(e.target.value);
    onChange(Number.parseFloat(getValueByRangePosition(
      value,
    ).toFixed(2)));
  };

  return (
    <input
      type="range"
      step="0.1"
      min="0"
      max="100"
      value={getRangePositionByValue()}
      onChange={handleRangeInvest}
      className={styles.range}
      aria-label="header.simulation.range"
    />
  );
}
