import React from 'react';

import styles from './InputText.module.css';

export default function InputText({
  className, type = 'text', name, value, onChange, onKeyDown, placeholder, disabled = false, children,
}: {
  className?: string, name?: string, type?: string,
  placeholder: string, value?: string, onChange: (e:React.ChangeEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  children?: React.ReactNode,
  disabled?: boolean
}) {
  return (
    <div className={`${className} ${styles.container} ${disabled ? styles.disabled : ''}`}>
      { children }
      <input
        type={type}
        name={name}
        step={0.01}
        id={name}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={styles.input}
        disabled={disabled}
      />
    </div>
  );
}
