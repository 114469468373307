import React from 'react';

import { Project } from 'types/project';

import ProjectHeader from './ProjectHeader';
import ProjectTitle from './ProjectTitle';
import ProjectContent from './ProjectContent';
import ProjectRecommendation from './ProjectRecommendation';
import ProjectQA from './ProjectQA';
import ProjectSimulation from './ProjectSimulation';
import ProjectNavigation from './ProjectNavigation';
import ProjectCTA from './ProjectCTA';

interface ProjectLayoutProps {
  data: Project
  setData: (newValue: Project) => void,
}

export default function ProjectLayout({ data, setData }: ProjectLayoutProps) {
  const getLanguages = () => {
    const lang = [];
    if (data.content.header.fr?.title) {
      lang.push('fr');
    }
    if (data.content.header.en?.title) {
      lang.push('en');
    }
    return lang;
  };

  return (
    <section>
      <ProjectHeader
        project={data}
        id={data.id}
        user={data.user}
        calendarLink={data.calendar}
        lang={getLanguages()}
        updateProject={setData}
      />
      <ProjectNavigation>
        {
          (section) => (
            <ProjectSimulation
              project={data}
              onUpdate={setData}
              showDocument={section === 'PROJECT'}
            >
              {
                (section === 'PROJECT')
                && (
                  <>
                    <ProjectTitle project={data} updateProject={setData} />
                    <ProjectContent
                      project={data}
                      updateProject={setData}
                    />
                    <ProjectCTA
                      calendar={data.calendar}
                    />
                  </>
                )
              }
              {
                (section === 'QUESTIONS')
                && (<ProjectQA />)
              }
              {
                (section === 'COMMENTS')
                && (
                  <ProjectRecommendation
                    project={data}
                    updateProject={setData}
                  />
                )
              }
            </ProjectSimulation>
          )
        }
      </ProjectNavigation>
    </section>
  );
}
