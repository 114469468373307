import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/Modal';
import ErrorAnimation from 'components/library/ErrorAnimation';
import Button from 'components/library/Button';
import useVisibility from 'hooks/useVisibility';
import AuthorizationContext from 'utils/AuthorizationContext';
import isIE from 'utils/isIE';
import trackuser from 'utils/trackuser';
import getErrorMessage from 'utils/error';
import { getProjectId } from 'utils/project';
import { trackWhatsAppEvent } from 'utils/logs';
import { sendRegistrationSMS, verifyRegistrationSMS } from 'services/network/api/project';
import { ProjectUser } from 'types/project';
import { ProjectContext } from 'context/project';

import WhatsApp from '../../ProjectSimulation/ProjectSimulationContent/WhatsAppButtonGreenLarge.svg';
import ProjectContentPopUpRegister from './ProjectContentPopUpRegister';
import styles from './ProjectContentPopUp.module.css';
import ProjectContentPopUpVerification from './ProjectContentPopUpVerification';
import ProjectContentPopUpCallBackRi from './ProjectContentPopUpCallBackRi';
import ProjectContentPopUpCreditError from './ProjectContentPopUpCreditError';

interface ProjectContentPopUpProps {
  project: string,
  calendar: string,
  phone: string,
}

export default function ProjectContentPopUp({
  project, calendar, phone,
}: ProjectContentPopUpProps) {
  const [step, setStep] = useState('register');
  const [error, setError] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentlyFillingForm, setIsCurrentlyFillingForm] = useState(false);

  const [isVisible, currentElement, setIsVisible] = useVisibility(0);
  const { isAdmin, mode } = useContext(ProjectContext);

  const { t } = useTranslation();
  const { isAuthorized, setIsAuthorized } = useContext(AuthorizationContext);

  useEffect(() => {
    if (isVisible) {
      trackuser(project, 135, 'Scroll 75% +');
    }

    if (!isAuthorized && (isVisible || isCurrentlyFillingForm)) {
      trackuser(project, 136, 'Affichage pop up de fin de page');
      if (!isIE()) document.body.classList.add('disable-scroll');
      document.body.addEventListener('touchstart', (e) => { e.preventDefault(); });
    } else if (!isIE() && document?.body?.classList?.value?.includes('disable-scroll')) {
      document.body.classList.remove('disable-scroll');
    }
  }, [isVisible, isAuthorized]);

  // TODO: remplacer le any
  const handleRequest = async (request: () => Promise<any>, callback: () => void) => {
    setIsLoading(true);
    try {
      const { data } = await request();
      if (data.error) {
        if (data.error === 'userExist' && setIsAuthorized) {
          setStep('call-back-ri');
        } else if (data.error === true) {
          setError('codeError');
        } else if (data.error !== '') {
          setError(data.error);
        }
      } else if (callback) {
        callback();
      }
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = (user: ProjectUser, callback : () => void) => {
    trackuser(project, 138, 'Validation pop up de fin de page');
    if (user.email) setEmail(user.email);
    handleRequest(
      () => sendRegistrationSMS(
        getProjectId().toString(),
        user.lastName || 'N/A',
        user.firstName || 'N/A',
        user.email || 'N/A',
        user.phone || 'N/A',
      ),
      callback,
    );
  };

  const handleVerification = async (code: string, callback: () => void) => {
    handleRequest(() => verifyRegistrationSMS(getProjectId().toString(), code, email), callback);
    setIsLoading(true);
  };

  const handleFillForm = () => {
    if (!isCurrentlyFillingForm) {
      trackuser(project, 137, 'Remplissage pop up de fin de page');
    }
    setIsCurrentlyFillingForm(true);
  };

  const handleRetry = () => setError(undefined);

  const onBack = () => {
    if (step === 'verification') {
      setStep('register');
    }
  };

  const handleSubmit = async ({ data, code }: { data?: ProjectUser, code?: string }) => {
    if (step === 'register' && data) {
      const callback = () => setStep('verification');
      await handleRegister(data, callback);
    }
    if (step === 'verification' && code) {
      const callback = () => {
        setStep('call-back-ri');
      };
      await handleVerification(code, callback);
    }
  };

  const close = () => {
    setIsCurrentlyFillingForm(false);
    setIsVisible(false);
    document.body.classList.remove('disable-scroll');
    window.scrollTo(0, 0);
  };

  const handleAccess = () => {
    if (setIsAuthorized) setIsAuthorized(true);
    document.getElementById('business')?.scrollIntoView({ behavior: 'smooth' });
  };

  const FORM_STEP = {
    register: <ProjectContentPopUpRegister
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onChange={handleFillForm}
      onClose={close}
    />,
    verification: <ProjectContentPopUpVerification
      isCodeValid={error !== 'codeError'}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onBack={onBack}
    />,
    'call-back-ri': <ProjectContentPopUpCallBackRi
      calendar={calendar}
      phone={phone}
      onAccess={handleAccess}
    />,
  };

  if (isAuthorized || isAdmin || (mode === 'edition')) return null;

  if (isVisible || isCurrentlyFillingForm) {
    return (
      <Modal
        disabledOutsideClick
        disabledScroll
      >
        {/* @ts-ignore */}
        <div ref={currentElement} />
        {
          (!error || error === 'codeError')
          && (
            FORM_STEP[step as keyof typeof FORM_STEP]
          )
        }
        {
          (error && error === 'numberError')
          && (
            <div className={styles.error}>
              <ErrorAnimation />
              <p className={styles.title}>
                {t('presentation.restricted.registration.number_not_supported')}
              </p>
              <p className={styles.description}>
                {t('presentation.restricted.registration.contacted_by_ayomi')}
              </p>
              <div
                className={styles.whatsapp}
              >
                <a
                  aria-label="Chat on WhatsApp"
                  href="https://wa.me/33755540446"
                  target="_blank"
                  rel="noreferrer"
                  onClick={trackWhatsAppEvent}
                >
                  <img alt="Chat on WhatsApp" src={WhatsApp} />
                </a>
              </div>
              <Button
                variant="ghost"
                onClick={handleRetry}
                className={styles.button}
              >
                {t('presentation.restricted.registration.retry_new_number')}
              </Button>
            </div>
          )
        }
        {
          (error && error !== 'numberError' && error !== 'codeError')
          && (
            <ProjectContentPopUpCreditError
              onClose={close}
            />
          )
        }
      </Modal>
    );
  }
  // @ts-ignore
  return (<div ref={currentElement} id="restricted" data-testid="restricted" />);
}
