import axios from 'axios';
import { getProjectId } from './project';
import { sendMessage } from '../services/network/api/telegram';
import getFingerPrint from './getFingerPrint';

export function getCookie(name: string) {
  let cookieValue = null;
  try {
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i += 1) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
      // Get cookie for IE (There are two different csrftoken in IE)
      if (navigator.appName === 'Microsoft Internet Explorer' || !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))) {
        for (let i = 0; i < cookies.length; i += 1) {
          const cookie = cookies[i].trim();
          // Does this cookie string begin with the name we want?
          // Get the last csrftoken for IE
          if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          }
        }
      }
      // console.log(cookieValue)
    }
    return cookieValue;
  } catch (err) {
    console.log('cookie failed');
    return undefined;
  }
}

axios.defaults.withCredentials = true;
axios.defaults.headers.put['X-CSRFToken'] = getCookie('csrftoken') || false;
axios.defaults.headers.post['X-CSRFToken'] = getCookie('csrftoken') || false;

let log37sent = false;

const trackUser = async (name = 'unknown', num = 0, description = 'vide') => {
  try {
    if (window.location.href.includes('localhost')) return;

    if (num === 37) log37sent = true;

    if (num === 122 && !log37sent) {
      sendMessage(`*[LOG ERROR - ${new Date().toLocaleString()}]:* %0A URL: ${window.location.href} %0A Description: Log 122 before log 37 on ${window.projectName}`);
    }

    const url = `/cofunding/amler/1655/${num}/log/df14/`;
    const parent = getCookie('ido');
    const formData = new FormData();
    if (!name || name === 'None' || `${name}`.trim() === '' || name === 'unknown' || name === '{{title}}') {
      sendMessage(`*[LOG ERROR - ${new Date().toLocaleString()}]:* %0A URL: ${window.location.href} %0A Action: ${num} Description: send ${name} window.projectName: ${window.projectName}`);
    }
    formData.append('name', `${name}`);
    formData.append('description', description);
    formData.append('num', num.toString());
    formData.append('url', window.location.href);
    formData.append('project_id', getProjectId().toString());
    if (parent) formData.append('parent', parent);

    try {
      await axios.post(
        url,
        formData,
        {
          withCredentials: true,
        },
      );
    } catch (error) {
      // TODO: send telegram message
    }
  } catch (err) {
    console.error(err);
  }
};

export const trackSimulationUser = async (
  inputValue: number | string,
  email: string,
  projectId: string,
  name: string,
  action = 50,
) => {
  if (action !== 33 && action !== 50) return;
  trackUser(name, action, (action === 50) ? `Montant testé ${inputValue}` : 'User push I invest');
  const input = window.sessionStorage.getItem('input');
  if (!input) {
    window.sessionStorage.setItem('input', inputValue.toString());
  }
  if (action === 33 || inputValue > (input ? Number.parseInt(input, 10) : 0)) {
    window.sessionStorage.setItem('input', inputValue.toString());
    const formData = new FormData();
    formData.append('email', email);
    formData.append('projectID', getProjectId().toString());
    formData.append('amount', inputValue.toString());
    formData.append('cookie', getCookie('landconf') || '');
    if (projectId && typeof projectId === 'object') {
      Object.keys(projectId).forEach((key) => {
        formData.append(key, projectId[key]);
      });
    }
    try {
      await axios.post('/cofunding/register/intention/resa/temp/', formData);
    } catch (err) {
      // TODO: send telegram message
    }
  }
};

export const getUserParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const entries = Array.from(urlParams.entries());
  const params: Record<string, string> = {};
  entries.forEach(([key, value]) => {
    params[key] = value;
  });
  return params;
};

const sendFingerprint = async () => {
  if (window.location.href.includes('localhost')) return;
  try {
    const fingerprint = await getFingerPrint();
    await axios.post('/logsapp/df15/print/by/foot/', fingerprint);
  } catch (err: unknown) {
    sendMessage(`[ERROR - FINGERPRINT]: Fingerprint failed to send. Reasons : ${err} url: ${window.location.href}`);
  }
};

export const launchTrackUser = (name: string) => {
  const timestamp = (new Date()).getTime();
  sendFingerprint();
  window.setTimeout(
    () => {
      if ((new Date()).getTime() >= (timestamp + (2 * 60 * 1000))) {
        trackUser(name, 59, 'Utilisateur resté 1 minutes sur la page projet');
      }
    },
    2 * 60 * 1000,
  );
  window.setTimeout(
    () => {
      if ((new Date()).getTime() >= (timestamp + (5 * 60 * 1000))) {
        trackUser(name, 61, 'Utilisateur resté 5 minutes sur la page projet');
      }
    },
    5 * 60 * 1000,
  );
  window.setTimeout(
    () => {
      if ((new Date()).getTime() >= (timestamp + (10 * 60 * 1000))) {
        trackUser(name, 62, 'Utilisateur resté 10 minutes sur la page projet');
      }
    },
    10 * 60 * 1000,
  );
  window.setTimeout(
    () => {
      if ((new Date()).getTime() >= (timestamp + (30 * 60 * 1000))) {
        trackUser(name, 63, 'Utilisateur resté 30 minutes sur la page projet');
      }
    },
    30 * 60 * 1000,
  );
};

export default trackUser;
