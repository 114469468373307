import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';

import Modal from 'components/Modal';
import Button from 'components/library/Button';
import InputText from 'components/library/InputText';
import Input from 'components/library/Input';
import InputFile from 'components/InputFile';

import { getProjectId } from 'utils/project';
import { ProjectTeam } from 'types/project';

import styles from './ProjectContentTeamEdit.module.css';

interface ProjectContentTeamEditProps {
  isDelete: boolean,
  selected?: ProjectTeam,
  onDelete: () => void,
  onUpdate: (value: ProjectTeam) => void,
  close: () => void,
}

export default function ProjectContentTeamEdit({
  isDelete, selected, onDelete, onUpdate, close,
}: ProjectContentTeamEditProps) {
  const [value, setValue] = useState(selected
    || {
      name: '',
      img: '',
      description: '',
      title: '',
    });
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((curr) => ({
      ...curr,
      [e.target.name as keyof typeof curr]: e.target.value,
    }));
  };

  const handlePathChange = (path: string) => {
    setValue((curr) => ({ ...curr, img: path }));
  };

  // TODO: remplacer les any
  const handleEditorChange = (event:any, editor: any) => {
    if (editor.getData() !== '') setValue((curr) => ({ ...curr, description: editor.getData() }));
    else setValue((curr) => ({ ...curr, description: '<p></p>' }));
  };

  const handleSubmit = () => onUpdate(value);

  if (isDelete && selected) {
    return (
      <Modal close={close}>
        <p className={styles.title}>
          {t('edition.team.delete')}
        </p>
        <div className={styles.row}>
          <Button
            variant="ghost"
            onClick={close}
          >
            {t('modal.cancel')}
          </Button>
          <Button
            className={styles.delete}
            onClick={onDelete}
          >
            {t('edition.team.trash')}
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      close={close}
      disabledOutsideClick
    >
      <p className={styles.title}>
        {t('edition.team.edit')}
      </p>
      <form className={styles.form}>
        <Input
          label={t('edition.team.add')}
          name="name"
        >
          <InputText
            placeholder={t('edition.founder.name')}
            name="name"
            value={value.name}
            onChange={handleChange}
          />
        </Input>
        <Input
          label={t('edition.founder.job')}
          name="title"
        >
          <InputText
            placeholder={t('edition.founder.job')}
            value={value.title}
            name="title"
            onChange={handleChange}
          />
        </Input>
        <Input
          label={t('edition.founder.longDescription')}
          name="description"
        >
          <div
            className={styles.editor}
          >
            <CKEditor
              className={styles.border}
              editor={BalloonEditor}
              data={value.description || ''}
              onChange={handleEditorChange}
            />
          </div>
        </Input>
        <Input
          label={t('edition.founder.profilePicture')}
          name="img"
          containerClassName={styles.inputFile}
        >
          <InputFile
            projectId={getProjectId()}
            name="img"
            path={value.img}
            setPath={handlePathChange}
          />
        </Input>
        <div className={styles.row}>
          <Button
            variant="ghost"
            onClick={close}
          >
            {t('modal.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!value.name || value.name.trim() === ''}
          >
            {t('edit.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
