import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import frenchFlag from 'assets/flag.svg';
import englishFlag from 'assets/englishflag.svg';
import { ProjectContext } from 'context/project';

import styles from './ProjectHeaderLang.module.css';

export default function ProjectHeaderLang() {
  const { setLang } = useContext(ProjectContext);
  const { i18n } = useTranslation();
  const getActiveLanguage = () => (i18n.language.includes('fr') ? 'fr' : 'en');

  const LANG = {
    fr: {
      flag: frenchFlag,
      label: 'Français',
    },
    en: {
      flag: englishFlag,
      label: 'English',
    },
  };

  const updateLang = () => {
    const newLang = (getActiveLanguage() === 'fr') ? 'en' : 'fr';
    i18n.changeLanguage(newLang);
    if (setLang) {
      setLang(newLang as 'fr' | 'en');
    }
  };

  return (
    <button
      type="button"
      aria-label="change lang"
      data-testid="lang-switch"
      className={styles.lang}
      onClick={updateLang}
    >
      <img
        className={styles.flag}
        src={LANG[(getActiveLanguage() === 'fr') ? 'en' : 'fr'].flag}
        alt={LANG[(getActiveLanguage() === 'fr') ? 'en' : 'fr'].label}
      />
      <span>
        {LANG[(getActiveLanguage() === 'fr') ? 'en' : 'fr'].label}
      </span>
    </button>
  );
}
