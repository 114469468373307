import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonStyle } from 'components/library/Button/Button';
import { ProjectContext } from 'context/project';
import trackUser from 'utils/trackuser';

import Button from '../library/Button';
import AskProjectAccessForm from './AskProjectAccessForm';

interface Props {
  variant?: ButtonStyle,
  phone: string,
  calendar: string,
}

export default function AskProjectAccess({ phone, calendar, variant = 'ghost' }: Props) {
  const [isOpen, setIsOpen] = useState(new URLSearchParams(window.location.search)?.get('form') === 'true');
  const { name } = useContext(ProjectContext);

  const { t } = useTranslation();

  const openModal = () => {
    setIsOpen(true);
    trackUser(name, 144, 'Click on get access');
  };

  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Button
        variant={variant}
        onClick={openModal}
      >
        {t('navigation.create_account')}
      </Button>
      {
        (isOpen)
        && (
          <AskProjectAccessForm
            onClose={closeModal}
            phone={phone}
            calendar={calendar}
          />
        )
      }
    </>
  );
}
