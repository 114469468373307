import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import InputText from 'components/library/InputText';
import Input from 'components/library/Input';
import { Project } from 'types/project';
import { ProjectContext } from '../../../../context/project';

import styles from './ProjectTitle.module.css';

interface ProjectTitleProps {
  project: Project,
  updateProject: (newProject: Project) => void,
}

export default function ProjectTitle({ project, updateProject }:ProjectTitleProps) {
  const { isAdmin, mode, lang } = useContext(ProjectContext);
  const { t } = useTranslation();

  const getLang = () => lang;

  const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newProject = {
      ...project,
      content: {
        ...project.content,
        title: {
          ...project.content.title,
          [getLang()]: e.target.value,
        },
      },
    };
    updateProject(newProject);
  };

  return (
    (isAdmin && mode === 'edition')
      ? (
        <form className={`${styles.container} ${styles.form}`}>
          <Input
            name="title"
            label={t('project.title')}
          >
            <InputText
              name="title"
              placeholder={t('project.title')}
              value={project.content.title[getLang()]}
              onChange={handleTitle}
            />
          </Input>
        </form>
      )
      : (
        <h2 className={`${styles.container} ${styles.title}`}>
          { project.content.title[getLang()] }
        </h2>
      )
  );
}
