import React from 'react';
import { Facebook, LinkedIn, Twitter } from '@mui/icons-material';

import { getCookie } from 'utils/trackuser';

import styles from './ProjectHeaderSocial.module.css';

export default function ProjectHeaderSocial() {
  const ido = getCookie('ido');
  return (
    <div
      className={styles.social}
    >
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${document.location.href}&origin=facebook&ido=${ido}`}
        rel="noreferrer"
        target="_blank"
        aria-label="share-facebook"
      >
        <Facebook />
      </a>
      <a
        href={`https://twitter.com/share?text=Découvrez cette opportunité&url=${document.location.href}&origin=twitter&ido=${ido}`}
        rel="noreferrer"
        target="_blank"
        aria-label="share-twitter"
      >
        <Twitter />
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${document.location.href}?origin=linkedin&ido=${ido}`}
        rel="noreferrer"
        target="_blank"
        aria-label="share-linkedin"
      >
        <LinkedIn />
      </a>
    </div>
  );
}
