import React, { createContext } from 'react';

const AuthenticationContext = createContext<{
  isAuthorized: boolean,
  isRejected: boolean,
  isConnected: boolean,
  setIsAuthorized?: React.Dispatch<React.SetStateAction<boolean>>,
}>({
  isAuthorized: window.connected === 'True',
  isRejected: true,
  isConnected: false,
});

export default AuthenticationContext;
