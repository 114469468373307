import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';
import { ProjectContext } from 'context/project';
import trackUser from 'utils/trackuser';

import styles from './ProjectManagerUserInterestPoll.module.css';

export type PollOption = { value: string, isPositive: boolean };

interface ProjectManagerUserInterestPollProps {
  onSubmit: (pollAnswers: PollOption[]) => void,
}

let hasSendPositiv = false;
let hasSendNegativ = false;

export default function ProjectManagerUserInterestPoll({
  onSubmit,
}: ProjectManagerUserInterestPollProps) {
  const [selected, setSelected] = useState<PollOption[]>([]);
  const { name } = useContext(ProjectContext);

  const { t } = useTranslation();

  const POLL_OPTIONS: PollOption[] = [
    {
      value: 'interested',
      isPositive: true,
    },
    {
      value: 'called',
      isPositive: true,
    },
    {
      value: 'information',
      isPositive: true,
    },
    {
      value: 'notInterested',
      isPositive: false,
    },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setSelected((curr) => {
    const newOption = POLL_OPTIONS.find((option) => option.value === e.target.value);
    if (newOption?.isPositive && !hasSendPositiv) {
      trackUser(name, 120, 'Utilisateur Intéressé');
      hasSendPositiv = true;
    } else if (newOption?.isPositive === false && !hasSendNegativ) {
      trackUser(name, 121, 'Utilisateur Non Intéressé');
      hasSendNegativ = true;
    }
    if (newOption) {
      if (e.target.checked) {
        return [...curr.filter((option) => option.isPositive === newOption.isPositive), newOption];
      }
      return curr.filter((option) => option.value !== newOption.value);
    }
    return curr;
  });

  const handleSubmit = () => onSubmit(selected);

  const isOptionSelected = (option: string) => selected.map(({ value }) => value).includes(option);

  return (
    <form className={styles.form}>
      {
        POLL_OPTIONS.map((option) => (
          <label
            key={option.value}
            htmlFor={option.value}
          >
            <input
              id={option.value}
              value={option.value}
              type="checkbox"
              checked={isOptionSelected(option.value)}
              onChange={handleChange}
              className={styles.option}
            />
            { t(`interest.${option.value}`) }
          </label>
        ))
      }
      <Button
        onClick={handleSubmit}
        disabled={selected.length === 0}
      >
        {t('interest.send')}
      </Button>
    </form>
  );
}
