import React, {
  useContext, useMemo, useState, useEffect, useCallback,
} from 'react';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PermissionDenied from 'components/PermissionDenied';
import useAPI from 'hooks/useAPI';
import { getProjectById, updateProjectById } from 'services/network/api/project';
import { toBackendProject, toProject } from 'services/network/adaptor/project';
import { StateType } from 'services/store/states';
import * as statuses from 'services/store/states';
import { addToast } from 'services/store/features/toasts/actions';
import { Project } from 'types/project';
import { getProjectId } from 'utils/project';
import AuthorizationContext from 'utils/AuthorizationContext';
import getErrorMessage from 'utils/error';
import { ProjectContextType, ProjectContext } from 'context/project';
import trackUser from 'utils/trackuser';

import ProjectManagerMode from './ProjectManagerMode';
import ProjectManagerSignIn from './ProjectManagerSignIn';
import ProjectManagerUserInterest from './ProjectManagerUserInterest';
import styles from './ProjectManager.module.css';
import ProjectClosed from '../ProjectClosed';

declare global {
  interface Window {
    notPermission?: number | string,
  }
}

interface ProjectManagerProps {
  children: (
    status: StateType,
    data: Project,
    error: string | null,
    setData: (newData: Project) => void,
  ) => React.ReactNode,
}

let isFirstTime = true;
let accessLogSend = false;

export default function ProjectManager({ children }: ProjectManagerProps) {
  const [isLoading, setIsLoading] = useState<boolean[]>([]);
  const [mode, setMode] = useState('preview');
  const [lang, setLang] = useState<'fr' | 'en'>('fr');
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { isConnected, isRejected } = useContext(AuthorizationContext);

  const {
    status, data, error, setData,
  } = useAPI({
    request: (source) => getProjectById(getProjectId(), source),
    adaptor: toProject,
  });

  const delayedUpdate = useCallback(
    debounce(
      async (project: Project) => {
        setIsLoading((curr) => [...curr, true]);
        try {
          const { data: updated } = await updateProjectById(
            Number.parseInt(project.id, 10),
            toBackendProject(project),
          );
          setIsLoading((curr) => curr.filter((_, index) => index !== 0));
          if (updated.error !== '') {
            dispatch(addToast({
              type: 'error',
              title: 'La mise à jour de la page projet a échoué',
              description: getErrorMessage(updated.error),
            }));
          }
        } catch (err) {
          setIsLoading((curr) => curr.filter((_, index) => index !== 0));
          dispatch(addToast({
            type: 'error',
            title: 'La mise à jour de la page projet a échoué',
            description: getErrorMessage(err),
          }));
        }
      },
      1000,
    ),
    [],
  );

  useEffect(() => {
    if (!accessLogSend) {
      trackUser(window.projectName, 37, 'Utilisateur accède à la page');
      accessLogSend = true;
    }
  }, []);

  useEffect(() => {
    if (data && isFirstTime && status === statuses.SUCCESS) {
      isFirstTime = false;
      setLang((data?.content?.header.en?.title && !i18n.language.includes('fr')) ? 'en' : 'fr');
      trackUser(data?.content?.header?.name, 122, 'Page chargée en entier');
    } else if (data && !isFirstTime) {
      delayedUpdate.cancel();
      delayedUpdate(data);
    }
  }, [data]);

  const modeContext: ProjectContextType = useMemo(() => ({
    mode: mode as 'preview' | 'edition',
    isAdmin: data?.isAdmin,
    name: data?.content?.header?.name || window.projectName,
    lang,
    setLang,
  }), [data, mode, lang]);

  return (
    <ProjectContext.Provider value={modeContext}>
      {
        (data && data.isFundraiseFinished)
        && (
          <ProjectClosed />
        )
      }
      {
        (isLoading.length > 0)
        && (
          <p className={`slideInTop ${styles.loading}`}>
            <span className="loader" />
            <span>{t('edition.loading')}</span>
          </p>
        )
      }
      {
        data?.isAdmin
        && (
          <ProjectManagerMode mode={mode} setMode={setMode} />
        )
      }
      {
        (isRejected)
        && (
          <PermissionDenied
            phone={data?.phone || ''}
            calendar={data?.calendar || ''}
          />
        )
      }
      {
        (status === statuses.SUCCESS && !data?.isAdmin)
        && (
          <ProjectManagerSignIn
            isConnected={isConnected}
            phone={data?.phone || ''}
            calendar={data?.calendar || ''}
          />
        )
      }
      {
        (!data?.isAdmin && !isRejected)
        && (
          <ProjectManagerUserInterest
            riPhone={data?.phone}
            calendar={data?.calendar}
          />
        )
      }
      { children(status, data, error, setData) }
    </ProjectContext.Provider>
  );
}
