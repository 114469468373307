import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Favorite,
  FavoriteBorder, Info, LocalOffer, LocationOn,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import { ProjectHeader } from 'types/project';
import { ProjectContext } from 'context/project';
import Button from 'components/library/Button';
import trackUser from 'utils/trackuser';

import ProjectHeaderSummaryValue from './ProjectHeaderSummaryValue';
import styles from './ProjectHeaderSummary.module.css';
import isLocalStorageSupported from '../../../../../utils/isLocalStorageSupported';

interface ProjectHeaderSummaryProps {
  header: ProjectHeader,
  minimum: number,
}

const localStorageSupported = isLocalStorageSupported();

export default function ProjectHeaderSummary({ header, minimum }: ProjectHeaderSummaryProps) {
  const [isFavorite, setIsFavorite] = useState(localStorageSupported ? window.localStorage.getItem('favorite') === 'true' : false);
  const { t } = useTranslation();
  const { lang } = useContext(ProjectContext);

  const getTags = () => {
    if (header[lang]?.tags
      && ((header[lang]?.tags || []).length > 0)
    ) {
      return (
        <p className={styles.hint}>
          <LocalOffer />
          {header[lang]?.tags.join(', ')}
        </p>
      );
    }
    return null;
  };

  const addAsFavorite = () => {
    const newFavorite = !isFavorite;
    if (localStorageSupported) {
      window.localStorage.setItem('favorite', (newFavorite).toString());
    }
    setIsFavorite(newFavorite);
    if (newFavorite) trackUser(window.projectName, 139, 'Click on alert me');
  };

  return (
    <section className={styles.summary}>
      <h1 className={styles.title}>
        {header[lang]?.title}
      </h1>
      <p className={styles.description}>
        {header[lang]?.description}
      </p>
      <div className={styles.grid}>
        <p className={styles.hint}>
          <LocationOn />
          <span>
            {header.city}
          </span>
        </p>
        { getTags() || <p /> }
        <ProjectHeaderSummaryValue
          label={t('header.funds')}
          value={`${Number.parseFloat(header.funds.replace('€', '')).toLocaleString()}€`}
        />
        <ProjectHeaderSummaryValue
          label={t('header.valuation')}
          value={`${Number.parseFloat(header.valuation.replace('€', '')).toLocaleString()}€`}
        >
          <Tooltip
            title={t('header.valuation_definition')}
            placement="top"
            arrow
          >
            <Info
              className={styles.info}
            />
          </Tooltip>
        </ProjectHeaderSummaryValue>
        <div>
          <ProjectHeaderSummaryValue
            label={t('header.investors')}
            value={(header.investors + (isFavorite ? 1 : 0)).toLocaleString()}
          />
          <Button
            type="primary"
            variant="outline"
            onClick={addAsFavorite}
          >
            {
              (isFavorite)
                ? <Favorite className={styles.favorite} />
                : <FavoriteBorder className={styles.favorite} />
            }
            <span>
              {t('header.favorite_project')}
            </span>
          </Button>
        </div>
        <ProjectHeaderSummaryValue
          label={t('header.minimum_amount')}
          value={`${minimum.toLocaleString()}€`}
        />
      </div>
    </section>
  );
}
