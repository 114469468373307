import { createContext, Dispatch, SetStateAction } from 'react';

export type ProjectContextType = {
  mode: 'preview' | 'edition',
  name: string,
  isAdmin: boolean,
  lang: 'fr' | 'en',
  setLang?: Dispatch<SetStateAction<'fr' | 'en'>>
};

export const initialContext: ProjectContextType = {
  mode: 'preview',
  isAdmin: false,
  lang: 'fr',
  name: window.projectName,
};

export const ProjectContext = createContext(initialContext);
