import {
  BackendProject, DocumentTmp, MemberTmp, Project,
} from 'types/project';

export const isProject = (value: unknown): value is BackendProject => !!value
  && typeof value === 'object'
  && 'id' in value
  && 'mode' in value
  && 'simulation' in value
  && 'presentation' in value
  && 'header' in value;

export const toProject = (data: unknown): Project => {
  if (!isProject(data)) {
    throw new Error('Project received from the API is not well formatted');
  }
  return ({
    id: data.id || 'N/A',
    lang: data.lang || 'fr',
    calendar: data.url_rdv,
    phone: data.phone,
    isFundraiseFinished: data.projectStatus === 0,
    isAdmin: data.mode === 'edition',
    simulation: {
      share: data.simulation.action || 0,
      rate: data.simulation.rate || 0,
      minimum: data.simulation.minimum || 500,
      taxExemption: data.simulation?.defisc?.map((tax: { name: string }) => tax.name) || [],
      isHolding: data?.presentation?.isHolding,
      emissionPrice: data?.presentation?.emissionPrice,
      unitPrice: data?.presentation?.unitPrice,
    },
    user: {
      phone: data?.userInfo?.numtel || '',
      firstName: data?.userInfo?.first_name || '',
      lastName: data?.userInfo?.last_name || '',
      email: data?.userInfo?.email || '',
    },
    content: {
      header: {
        city: data.header?.city || '',
        name: data.header?.name || '',
        logo: data.header?.logo || '',
        share: data.header?.action || '',
        funds: data.header?.funds || '',
        investors: data.header?.investor || 0,
        valuation: data.header?.postmoney || '',
        picture: data.header?.headerPictures || '',
        fr: {
          tags: data.header?.fr?.tags || [],
          title: data.header?.fr?.title,
          description: data.header?.fr?.description || '',
        },
        en: {
          tags: data.header?.en?.tags || [],
          title: data.header?.en?.title || '',
          description: data.header?.en?.description || '',
        },
      },
      title: {
        fr: data?.presentation?.fr?.title,
        en: data?.presentation?.en?.title,
      },
      founders: data.founders,
      metrics: {
        isDisplay: data?.presentation?.displayMetriques || false,
        fr: data.presentation?.fr?.metriques,
        en: data.presentation?.en?.metriques,
      },
      business: {
        isDisplay: data?.presentation?.displayBusiness || false,
        fr: data.presentation?.fr?.business,
        en: data.presentation?.en?.business,
        pictures: [
          data.presentation.businessPicture1,
          data.presentation.businessPicture2,
          data.presentation.businessPicture3,
        ],
      },
      market: {
        isDisplay: data?.presentation?.displayMarket || false,
        fr: data.presentation?.fr?.market,
        en: data.presentation?.en?.market,
        pictures: [
          data.presentation.marketPicture1,
          data.presentation.marketPicture2,
        ],
      },
      support: {
        isDisplay: data?.presentation?.displaySupport || false,
        fr: data.presentation?.fr?.support,
        en: data.presentation?.en?.support,
        pictures: [
          data.presentation.supportPicture1,
        ],
      },
      finance: {
        isDisplay: true,
        pictures: [data.presentation.financeProjectionImage],
        en: data.presentation?.en?.finance,
        fr: data.presentation?.fr?.finance,
      },
      breakdownOfFunds: {
        isDisplay: true,
        fr: data.presentation?.fr?.breakdownOfFunds,
        en: data.presentation?.en?.breakdownOfFunds || data.presentation?.fr?.breakdownOfFunds,
      },
      partner: {
        isDisplay: data?.presentation?.displayPartner || false,
        pictures: [data.presentation?.partnerPicture1],
        en: data.presentation?.en?.partner,
        fr: data.presentation?.fr?.partner,
      },
      project: {
        isDisplay: data?.presentation?.displayProject || false,
        pictures: [data?.presentation?.projectPicture1, data?.presentation?.projectPicture2],
        en: data.presentation?.en?.project,
        fr: data.presentation?.fr?.project,
      },
      social: {
        isDisplay: data?.presentation?.displaySocial || false,
        pictures: [data?.presentation?.socialPicture1],
        en: data.presentation?.en?.social || '',
        fr: data.presentation?.fr?.social || '',
      },
      solution: {
        isDisplay: data?.presentation?.displaySolution || false,
        fr: data.presentation?.fr?.solution,
        en: data.presentation?.en?.solution,
        pictures: [
          data.presentation.solutionPicture,
        ],
      },
      roadmap: {
        isDisplay: data?.presentation?.displayRoadmap || false,
        fr: data.presentation?.fr?.roadmap || '',
        en: data.presentation?.en?.roadmap || '',
        pictures: [
          data?.presentation?.roadmapPicture1,
        ],
      },
      documents: {
        isDisplay: data?.presentation?.displayDocuments || true,
        fr: data.presentation.fr?.documents?.map((doc: DocumentTmp) => ({
          file: doc?.url || '',
          title: doc?.title || '',
        })),
        en: data.presentation.en?.documents?.map((doc: DocumentTmp) => ({
          file: doc?.url || '',
          title: doc?.title || '',
        })),
      },
      team: {
        isDisplay: true,
        fr: data.presentation?.fr?.team?.map((member: MemberTmp) => ({
          name: member?.name || '',
          img: member?.profilPictures || member?.img || '',
          title: member?.title || '',
          description: member?.description || '',
        })) || [],
        en: data.presentation?.en?.team?.map((member: MemberTmp) => ({
          name: member?.name || '',
          img: member?.profilPictures || member?.img || '',
          title: member?.title || '',
          description: member?.description || '',
        })) || [],
        raw: {
          fr: data?.presentation?.team[0]?.fr || '',
          en: data?.presentation?.team[0]?.en || '',
        },
      },
      reward: {
        isDisplay: !!(data.prix_recompense && data.prix_recompense[0]?.isDisplay) || false,
        fr: (data.prix_recompense && data.prix_recompense[0]?.fr) || '',
        en: (data.prix_recompense && data.prix_recompense[0]?.en) || '',
      },
      press: {
        isDisplay: !!(data.ils_parlent_de_nous && data.ils_parlent_de_nous[0]?.isDisplay)
          || false,
        fr: (data.ils_parlent_de_nous && data.ils_parlent_de_nous[0]?.fr) || '',
        en: (data.ils_parlent_de_nous && data.ils_parlent_de_nous[0]?.en) || '',
      },
    },
    recommandation: data.recommandation,
  });
};

export const toBackendProject = (project: Project): BackendProject => ({
  id: project.id,
  template_id: 'baseTemplate',
  mode: project.isAdmin ? 'edition' : 'preview',
  url_rdv: project.calendar,
  phone: project.phone,
  projectStatus: project.isFundraiseFinished ? 1 : 0,
  prix_recompense: [
    {
      isDisplay: project.content.reward.isDisplay,
      en: project.content.reward.en || '',
      fr: project.content.reward.fr || '',
    },
  ],
  ils_parlent_de_nous: [
    {
      isDisplay: project.content.press.isDisplay,
      en: project.content.press.en || '',
      fr: project.content.press.fr || '',
    },
  ],
  header: {
    fr: {
      tags: project.content.header.fr?.tags || [],
      description: project.content.header.fr?.description || '',
      title: project.content.header.fr?.title || '',
    },
    en: {
      tags: project.content.header.en?.tags,
      description: project.content.header.en?.description,
      title: project.content.header.en?.title,
    },
    funds: project.content.header.funds,
    logo: project.content.header.logo,
    investor: project.content.header.investors,
    city: project.content.header.city,
    name: project.content.header.name,
    postmoney: project.content.header.valuation,
    headerPictures: project.content.header.picture,
    action: project.content.header.share,
  },
  founders: project.content.founders,
  presentation: {
    businessPicture1: (project.content.business.pictures ? project.content.business.pictures[0] : ''),
    businessPicture2: '',
    businessPicture3: '',
    marketPicture1: (project.content.market.pictures ? project.content.market.pictures[0] : ''),
    marketPicture2: '',
    supportPicture1: (project.content.support.pictures ? project.content.support.pictures[0] : ''),
    financeProjectionImage: project.content.finance.pictures ? project.content.finance.pictures[0] : '',
    displaySupport: project.content.support.isDisplay,
    en: {
      metriques: project.content.metrics.en || '',
      finance: project.content.finance.en || '',
      business: project.content.business.en || '',
      social: project.content.social.en || '',
      financePropositions: [],
      roadmap: project.content.roadmap.en || '',
      partner: project.content.partner.en || '',
      market: project.content.market.en || '',
      documents: project.content.documents.en
        ?.map((doc) => ({ url: doc.file, title: doc.title })) || [],
      title: project.content.title?.en || '',
      support: project.content.support.en || '',
      solution: project.content.solution.en || '',
      project: project.content.project.en || '',
      team: project.content.team.en || [],
      breakdownOfFunds: project.content.breakdownOfFunds.en || [],
    },
    projectPicture2: '',
    projectPicture1: project.content.project.pictures ? project.content.project.pictures[0] : '',
    displaySolution: project.content.solution.isDisplay,
    displayDocuments: project.content.documents.isDisplay,
    solutionPicture: project.content.solution.pictures ? project.content.solution.pictures[0] : '',
    displayTeam: project.content.team.isDisplay,
    displayMarket: project.content.market.isDisplay,
    fr: {
      metriques: project.content.metrics.fr || '',
      finance: project.content.finance.fr || '',
      business: project.content.business.fr || '',
      social: project.content.social.fr || '',
      financePropositions: [],
      roadmap: project.content.roadmap.fr || '',
      partner: project.content.partner.fr || '',
      market: project.content.market.fr || '',
      documents: project.content.documents.fr
        ?.map((doc) => ({ url: doc.file, title: doc.title })) || [],
      title: project.content.title?.fr || '',
      support: project.content.support.fr || '',
      solution: project.content.solution.fr || '',
      project: project.content.project.fr || '',
      team: project.content.team.fr || [],
      breakdownOfFunds: project.content.breakdownOfFunds.fr || [],
    },
    displayProject: project.content.project.isDisplay,
    partnerPicture1: project.content.partner.pictures ? project.content.partner.pictures[0] : '',
    displayMetriques: project.content.metrics.isDisplay,
    displayPartner: project.content.partner.isDisplay,
    displayRoadmap: project.content.roadmap.isDisplay,
    displaySocial: project.content.social.isDisplay,
    displayBusiness: project.content.business.isDisplay,
    roadmapPicture1: project.content.roadmap.pictures ? project.content.roadmap.pictures[0] : '',
    socialPicture1: project.content.social.pictures ? project.content.social.pictures[0] : '',
    team: [
      {
        fr: project.content.team.raw?.fr || '',
        en: project.content.team.raw?.en || '',
      },
    ],
    isHolding: project.simulation.isHolding,
    emissionPrice: project.simulation.emissionPrice || 0,
    unitPrice: project.simulation.unitPrice || 0,
  },
  recommandation: project.recommandation,
  simulation: {
    action: project.simulation.share,
    rate: project.simulation.rate,
    minimum: project.simulation.minimum,
    defisc: project.simulation.taxExemption.map((name) => ({ name })),
  },
  lang: project.lang,
});
