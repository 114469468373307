import React, { useContext } from 'react';
import { Home } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';
import AskProjectAccess from 'components/AskProjectAccess';
import AuthorizationContext from 'utils/AuthorizationContext';

import styles from './ProjectManagerSignIn.module.css';

interface ProjectManagerSignInProps {
  isConnected: boolean,
  phone: string,
  calendar: string,
}

export default function ProjectManagerSignIn({
  isConnected, phone, calendar,
}: ProjectManagerSignInProps) {
  const { t } = useTranslation();
  const { isAuthorized } = useContext(AuthorizationContext);

  const navigateToAuth = () => {
    let { host } = window.location;
    const firstPoint = host.indexOf('.');
    if ((host.split('.').length) > 2 && firstPoint !== -1) {
      host = `${host.slice(0, firstPoint)}-auth${host.slice(firstPoint, host.length)}`;
      const url = `https://${host}/?redirect=${window.location.href}`;
      window.location.assign(url);
    } else if (window.projectName && window.projectName.trim() !== '' && window.projectName !== '{{title}}') {
      window.location.assign(`https://${window.projectName}-auth.lendeers.com/?redirect=${window.location.href}`);
    }
  };

  if (isConnected) {
    return (
      <div className={`fadeIn ${styles.connected}`}>
        <a
          href="https://dashboard.ayomi.fr/"
          className={styles.link}
        >
          <Home className={styles.icon} />
          <p>{t('navigation.dashboard')}</p>
        </a>
      </div>
    );
  }

  return (
    <div className={styles.logout}>
      {
        (!isAuthorized)
        && (<AskProjectAccess phone={phone} calendar={calendar} />)
      }
      <Button
        onClick={navigateToAuth}
      >
        {t('navigation.login.button')}
      </Button>
    </div>
  );
}
