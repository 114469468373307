import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { useEffect, useState } from 'react';

import * as statuses from 'services/store/states';
import getErrorMessage from '../utils/error';
import { keysToCamel } from '../utils/converter';

interface UseAPIProps {
  request: (source: CancelTokenSource) => Promise<AxiosResponse>,
  adaptor?: (data: Record<string, any>) => Record<string, any>,
  cached?: string,
}

// TODO: remove any, use generic type
export default function useAPI({ request, adaptor, cached }: UseAPIProps) {
  const [status, setStatus] = useState(statuses.IDLE);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>();

  let call = 0;

  const fetchData = async (source?: CancelTokenSource) => {
    let newSource = source;
    if (!newSource) {
      const cancelToken = axios.CancelToken;
      newSource = cancelToken.source();
    }
    setStatus(statuses.LOADING);
    const cachedResult = cached ? window.sessionStorage.getItem(cached) : undefined;
    let response;
    try {
      if (cachedResult) {
        response = JSON.parse(cachedResult);
      } else {
        const { data: responseData } = await request(newSource);
        response = responseData;
        if (cached) {
          window.sessionStorage.setItem(cached, JSON.stringify(response));
        }
      }
      setData((adaptor) ? adaptor(keysToCamel(response)) : keysToCamel(response));
      setStatus(statuses.SUCCESS);
    } catch (err) {
      setError(getErrorMessage((err as AxiosError)?.response?.data?.error || err));
      if (call > 0) {
        setStatus(statuses.FAIL);
      }
    } finally {
      call -= 1;
    }
  };

  const handleFetchData = (source?: CancelTokenSource) => {
    call += 1;
    fetchData(source);
  };

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    if (status === statuses.IDLE) {
      handleFetchData(source);
    }

    return () => source.cancel('axios request cancelled');
  }, []);

  return {
    status, data, error, setData, fetchData: handleFetchData,
  };
}
