import React from 'react';
import {
  Cancel, CheckCircle, Error, Info,
} from '@mui/icons-material';

// eslint-disable-next-line no-unused-vars
let id = 0;

type ToastProperties = {
  icon: React.ReactNode,
  color: string,
};

const toastPropertiesByType = (type: 'default' | 'success' | 'warning' | 'error' | 'custom', color: string, icon: React.ReactNode): ToastProperties => {
  const properties = {
    default: {
      icon: <Info />,
      color: '#4F67F3',
    },
    success: {
      icon: <CheckCircle />,
      color: '#00D287',
    },
    warning: {
      icon: <Error />,
      color: '#FBC506',
    },
    error: {
      icon: <Cancel />,
      color: '#F24C27',
    },
    custom: {
      icon,
      color,
    },
  };
  return properties[type];
};

const toastFactory = ({
  type = 'default', title, description, color, icon, autoDeleteTime = true,
}: {
  type: 'default' | 'success' | 'warning' | 'error' | 'custom',
  title: string,
  description: string,
  color?: string,
  icon?: React.ReactNode,
  autoDeleteTime?: boolean,
}) => {
  id += 1;
  return {
    ...toastPropertiesByType(type, color || '', icon),
    autoDeleteTime,
    id,
    title,
    description,
  };
};

export default toastFactory;

export type Toast = {
  id?: number,
  type: 'default' | 'success' | 'warning' | 'error' | 'custom',
  title: string,
  description: string,
  color?: string,
  icon?: React.ReactNode,
  autoDeleteTime?: boolean,
};
