import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Download, Euro, Lock } from '@mui/icons-material';
import { Trans, useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';

import Input from 'components/library/Input';
import Button from 'components/library/Button';
import SimulationInputRange from 'components/SimulationInputRange';
import InvestButton from 'components/InvestButton';
import trackUser from 'utils/trackuser';
import AuthorizationContext from 'utils/AuthorizationContext';
import useInvest from 'hooks/useInvest';
import { getProjectId } from 'utils/project';
import { ProjectDocumentContent, ProjectHeader, ProjectSimulation } from 'types/project';

import styles from './ProjectSimulationContent.module.css';

interface ProjectSimulationContentProps {
  project: string,
  calendar: string,
  simulation: ProjectSimulation,
  header: ProjectHeader,
  documents: ProjectDocumentContent,
}

export default function ProjectSimulationContent({
  project, calendar, simulation, header, documents,
}: ProjectSimulationContentProps) {
  const [taxType, setTaxType] = useState(simulation.taxExemption[0] || 'N/A');
  const { t } = useTranslation();
  const { isAuthorized } = useContext(AuthorizationContext);

  const {
    input,
    invest,
    type,
    setInput, handleClick,
    handleInvest, handleShowInvest, handleSimulation,
  } = useInvest({
    minimum: simulation.minimum || 500,
    maximum: Number.parseFloat(header.funds),
    valuation: Number.parseFloat(header.valuation),
    sharePrice: simulation.share,
  });

  const debouncedTrack = useCallback(
    debounce((nextInvest) => trackUser(window.projectName, 50, `Montant testé ${nextInvest}`), 1000),
    [],
  );

  useEffect(() => {
    if (invest !== simulation.minimum) {
      debouncedTrack(invest);
    }
  }, [invest]);

  const handleRangeChange = (value: number) => handleSimulation(value, setInput);
  const handleTaxChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTaxType(e.target.value);
    trackUser(window.projectName, 52, 'Modifie la tax');
  };

  const openCalendar = () => {
    trackUser(project, 123, 'Prendre un rendez-vous');
    window.open(calendar);
  };

  const accessDocument = () => {
    if (isAuthorized) {
      window.scrollTo(0, (document.getElementById('documents')?.offsetTop || 0) - 250);
    } else {
      window.scrollTo(0, document.getElementById('restricted')?.offsetTop || 0);
    }
    trackUser(window.projectName, 74, 'Demande de téléchargement de documents');
  };

  // @ts-ignore
  return (
    <div className={styles.column}>
      <div className={styles.simulation}>
        <p className={styles.title}>
          <Euro className={styles.icon} />
          {t('simulation.title')}
        </p>
        <Input
          label={t('simulation.money')}
          name="invest"
          containerClassName={styles.inputContainer}
        >
          <input
            type={type}
            id="invest"
            name="simulation-input"
            className={styles.input}
            value={input}
            aria-label={t('header.simulation.label')}
            onChange={handleInvest}
            onBlur={handleShowInvest}
            onClick={handleClick}
          />
        </Input>
        <SimulationInputRange
          minimum={simulation.minimum}
          maximum={Number.parseFloat(header.funds)}
          valuation={Number.parseInt(header.valuation, 10)}
          invest={invest}
          onChange={handleRangeChange}
        />
        {
          (
            simulation.rate !== 0
            && (taxType.toLowerCase() === 'ir')
          )
          && (
            <p className={`bounceIn ${styles.reduction}`}>
              <Trans
                i18nKey="simulation.reduction"
                values={{ value: t('currency', { value: ((simulation.rate * invest) / 100).toFixed(2) }) }}
              />
            </p>
          )
        }
        <div className={styles.grid}>
          {
            (simulation.taxExemption.length > 0)
            && (
              <>
                <p className={styles.gridTitle}>{t('simulation.tax')}</p>
                {
                  (simulation.taxExemption.length === 1) && <p>{simulation.taxExemption[0]}</p>
                }
                {
                  (simulation.taxExemption.length > 1)
                  && (
                    <select
                      value={taxType}
                      onChange={handleTaxChange}
                      className={styles.select}
                      aria-label="simulation.tax"
                    >
                      {
                        simulation.taxExemption.map((tax) => (
                          <option
                            key={tax}
                            value={tax}
                          >
                            {t(tax.toLowerCase())}
                          </option>
                        ))
                      }
                    </select>
                  )
                }
              </>
            )
          }
          <p className={styles.gridTitle}>{t('simulation.buy')}</p>
          <p className={styles.line}>
            {
              (Number.parseInt((invest / simulation.share).toFixed(0), 10)) < 1000
                ? (
                  t('simulation.shareBuy', {
                    count: Number.parseInt((invest / simulation.share).toFixed(0), 10),
                    percent: (((100 * invest) / Number.parseFloat(header.valuation)) || 0)
                      .toFixed(2),
                  })
                )
                : (
                  t('simulation.shareBuy_many', {
                    shares: Number.parseInt((invest / simulation.share).toFixed(0), 10)
                      .toLocaleString(),
                    percent: (((100 * invest) / Number.parseFloat(header.valuation)) || 0)
                      .toFixed(2),
                  })
                )
            }
          </p>
          <p className={styles.gridTitle}>{t('simulation.payment')}</p>
          <p className={styles.list}>
            <span>{t('simulation.paymentMethod.cb')}</span>
            <span>{t('simulation.paymentMethod.sepa')}</span>
            <span>{t('simulation.paymentMethod.transfer')}</span>
          </p>
        </div>
      </div>
      <InvestButton
        project={project}
        history={invest}
        id={getProjectId().toString()}
      />
      <Button
        type="secondary"
        onClick={openCalendar}
        className={styles.calendar}
      >
        {t('header.moreInfo')}
      </Button>
      {
        (documents.fr && documents.fr.length > 0)
        && (
          <div className={styles.document}>
            <p className={styles.lock}>
              <Lock />
              <span>
                {t('navigation.documents')}
              </span>
            </p>
            <button
              type="button"
              className={styles.link}
              onClick={accessDocument}
            >
              <Download />
              <span>
                {documents.fr && documents.fr[0]?.title}
              </span>
            </button>
            <Button
              variant="outline"
              className={styles.more}
              onClick={accessDocument}
            >
              {t('simulation.moreDocuments')}
            </Button>
          </div>
        )
      }
    </div>
  );
}
