import React from 'react';

import styles from './Button.module.css';

type ButtonType = 'primary' | 'secondary' | 'custom';
export type ButtonStyle = 'fill' | 'outline' | 'ghost';

type ButtonTypes = {
  className?: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  children: React.ReactNode,
  type?: ButtonType,
  variant?: ButtonStyle,
  disabled?: boolean,
  id?: string | number,
  label?: string,
};

export default function Button({
  className = '', onClick, id, children, type = 'secondary', variant = 'fill', label,
  disabled = false,
}: ButtonTypes) {
  return (
    <button
      type="button"
      className={`${styles.button} ${styles[type]} ${styles[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled}
      data-id={id}
      id={id?.toString()}
      aria-label={label}
    >
      { children }
    </button>
  );
}
