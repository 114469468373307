import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon-block';
import styles from '../ProjectContentSection.module.css';

// TODO: remplacer le any

interface Props {
  data: string,
  className?: string,
  onChange: any,
}

export default function ProjectContentSectionEdition({ data, className, onChange }: Props) {
  return (
    <div
      className={className || styles.editor}
    >
      <CKEditor
        className={styles.border}
        editor={BalloonEditor}
        data={data}
        onChange={onChange}
      />
    </div>
  );
}
