import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';

import book from './book.png';
import styles from './ProjectContentPopUpCallBackRi.module.css';

interface Props {
  calendar: string;
  phone: string;
  onAccess: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function ProjectContentPopUpCallBackRi({ calendar, phone, onAccess }: Props) {
  const { t } = useTranslation();

  const openCalendar = () => {
    window.open(calendar, '_blank');
  };

  return (
    <div className={styles.content}>
      <img
        src={book}
        alt="confidential document"
      />
      <p className={styles.title}>
        {t('callback-ri.title-document')}
      </p>
      <p className={styles.description}>
        {t('callback-ri.calling')}
        <span className={styles.phone}>
          {phone}
        </span>
        .
      </p>
      <Button
        onClick={openCalendar}
      >
        {t('callback-ri.rdv')}
      </Button>
      <Button
        onClick={onAccess}
        variant="ghost"
        className={styles.ghost}
      >
        {t('callback-ri.access')}
      </Button>
    </div>
  );
}
