import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ProjectError.module.css';

interface ProjectErrorProps {
  error: string | null,
}

export default function ProjectError({ error }: ProjectErrorProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.error}>
      <p className={styles.title}>
        {t('error.404.title')}
      </p>
      <p className={styles.description}>
        {t('error.404.description')}
      </p>
      <details className={styles.details}>
        {error}
      </details>
    </div>
  );
}
