import React, { useContext, useState } from 'react';
import { Error, Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { sendRegistrationSMS, verifyRegistrationSMS } from 'services/network/api/project';
import checkEmail from 'utils/checkEmail';
import { getProjectId } from 'utils/project';
import AuthorizationContext from 'utils/AuthorizationContext';
import trackUser from 'utils/trackuser';

import Input from '../../library/Input';
import InputText from '../../library/InputText';
import InputPhone from '../../InputPhone';
import Button from '../../library/Button';
import Spinner from '../../Spinner';
import Modal from '../../Modal';

import styles from '../AskProjectAccess.module.css';
import AccessFormRequestSent from './AccessFormRequestSent';
import ProjectContentPopUpCallBackRi
  from '../../../views/Project/ProjectLayout/ProjectContent/ProjectContentPopUp/ProjectContentPopUpCallBackRi';
import ProjectContentPopUpCreditError
  from '../../../views/Project/ProjectLayout/ProjectContent/ProjectContentPopUp/ProjectContentPopUpCreditError';

interface Props {
  calendar: string,
  phone: string,
  onClose: () => void,
}

let hasBeginToCompleteForm = false;

export default function AskProjectAccessForm({ calendar, phone, onClose }: Props) {
  const [formStep, setFormStep] = useState('FORM');
  const [user, setUser] = useState({ email: new URLSearchParams(window.location.search)?.get('email') || '', phone: '' });
  const [code, setCode] = useState('');
  const [error, setError] = useState({
    phone: false,
    email: false,
    code: false,
    server: false,
    registration: false,
    confirmation: false,
  });
  const [loading, setLoading] = useState(false);

  const { setIsAuthorized } = useContext(AuthorizationContext);
  const { t } = useTranslation();

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser((curr) => ({
      ...curr,
      email: e.target.value,
    }));
    if (!hasBeginToCompleteForm) {
      hasBeginToCompleteForm = true;
      trackUser(window.projectName, 137, 'Remplissage pop up de fin de page');
    }
  };

  const onPhoneChange = (value: string) => {
    setUser((curr) => ({
      ...curr,
      phone: value,
    }));
    if (!hasBeginToCompleteForm) {
      hasBeginToCompleteForm = true;
      trackUser(window.projectName, 137, 'Remplissage pop up de fin de page');
    }
  };

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value);
  const onPhoneError = (isValid: boolean) => {
    setError((curr) => ({ ...curr, phone: !isValid }));
  };

  const submitForm = async () => {
    if (!user.email || !user.phone || error.phone) {
      setError((curr) => ({
        ...curr,
        email: !user.email || !checkEmail(user.email),
        phone: !user.phone || curr.phone,
      }));
    } else {
      setLoading(true);
      try {
        trackUser(window.projectName, 138, 'Validation pop up d\'accès à la page');
        const { data } = await sendRegistrationSMS(
          getProjectId().toString(),
          '',
          '',
          user.email,
          user.phone,
        );
        if (!data.error) {
          setFormStep('CONFIRMATION');
          setError((curr) => ({ ...curr, email: false, phone: false }));
        } else if (data.error === 'userExist') {
          setFormStep('CALLBACK_RI');
        } else if (data.error === 'RedirectionInscription') {
          setFormStep('ACCESS_REQUEST_SENT');
          trackUser(window.projectName, 149, 'Envoi de demande d\'accès à la page');
        } else if (data.error === 'creditError') {
          setFormStep('CREDIT_ERROR');
        } else {
          setError((curr) => ({
            ...curr,
            email: false,
            phone: false,
            registration: data.error,
          }));
        }
      } catch (err) {
        setError((curr) => ({ ...curr, server: true }));
      } finally {
        setLoading(false);
      }
    }
  };

  const submitConfirmation = async () => {
    if (!code) {
      setError((curr) => ({ ...curr, code: true }));
    } else {
      setLoading(true);
      try {
        const { data } = await verifyRegistrationSMS(
          getProjectId().toString(),
          code,
          user.email,
        );
        if (!data.error) {
          setFormStep('CALLBACK_RI');
        } else {
          setError((curr) => ({ ...curr, confirmation: (data.error === true) ? 'codeError' : data.error }));
        }
      } catch (err) {
        setError((curr) => ({ ...curr, server: true }));
      } finally {
        setLoading(false);
      }
    }
  };

  const onBack = () => {
    if (formStep === 'CONFIRMATION') {
      setFormStep('FORM');
    }
  };

  const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (loading) return;
    if (formStep === 'FORM') submitForm();
    if (formStep === 'CONFIRMATION') submitConfirmation();
    if (formStep === 'CALLBACK_RI') {
      if (setIsAuthorized) setIsAuthorized(true);
      if (document.body.classList.value.includes('disable-scroll')) {
        document.body.classList.remove('disable-scroll');
      }
      onClose();
    }
  };

  return (
    <Modal close={onClose} disabledOutsideClick>
      {
        (formStep === 'FORM')
        && (
          <>
            <h1 className={styles.title}>
              {t('project_access.ask_access')}
            </h1>
            <p className={styles.description}>
              {t('project_access.ask_access_description')}
            </p>
            {
              (error.server || error.registration)
              && (
                <p className={styles['server-error']}>
                  <span className={styles.title}>
                    <Info className={styles.icon} />
                    <span className={styles.hint}>
                      {
                              error.server
                                ? t('project_access.server')
                                : t(`project_access.${error.registration}`)
                            }
                    </span>
                  </span>
                  <span className={styles.description}>
                    {
                            error.server
                              ? t('project_access.server_description')
                              : t(`project_access.${error.registration}_description`)
                          }
                  </span>
                </p>
              )
            }
            <form className={styles.form}>
              <Input
                label={t('project_access.email')}
                name="email"
              >
                <InputText
                  placeholder={t('project_access.email_placeholder')}
                  value={user.email}
                  name="email"
                  onChange={onEmailChange}
                />
                {
                  (error.email)
                  && (
                    <p className={styles.error}>
                      <Error className={styles.icon} />
                      {t('project_access.email_error')}
                    </p>
                  )
                }
              </Input>
              <Input
                label={t('project_access.phone')}
                name="phone"
              >
                <InputPhone
                  placeholder={t('project_access.phone_placeholder')}
                  name="phone"
                  value={user.phone}
                  onChange={onPhoneChange}
                  onError={onPhoneError}
                />
                {
                  (error.phone)
                  && (
                    <p className={styles.error}>
                      <Error className={styles.icon} />
                      {t('project_access.phone_error')}
                    </p>
                  )
                }
              </Input>
              <div className={styles['button-group']}>
                <Button
                  onClick={submit}
                  id="request-access"
                >
                  {
                    loading
                      ? (
                        <>
                          <Spinner />
                          <span className={styles.submitting}>
                            {t('project_access.submitting')}
                          </span>
                        </>
                      )
                      : t('project_access.get_access')
                  }
                </Button>
                <Button
                  variant="ghost"
                  onClick={onClose}
                >
                  {t('project_access.close')}
                </Button>
              </div>
            </form>
          </>
        )
      }
      {
        (formStep === 'CONFIRMATION')
        && (
          <>
            <h1 className={styles.title}>
              {t('modal.smsTitle')}
            </h1>
            <p className={styles.description}>
              {t('modal.smsDescription')}
            </p>
            {
              (error.server || error.confirmation)
              && (
                <p className={styles['server-error']}>
                  <span className={styles.title}>
                    <Info className={styles.icon} />
                    <span className={styles.hint}>
                      {
                              error.server
                                ? t('project_access.server')
                                : t(`project_access.${error.confirmation}`)
                            }
                    </span>
                  </span>
                  <span className={styles.description}>
                    {
                            error.server
                              ? t('project_access.server_description')
                              : t(`project_access.${error.confirmation}_description`)
                          }
                  </span>
                </p>
              )
            }
            <form className={styles.form}>
              <Input
                label={t('presentation.restricted.receiveCode')}
                name="code"
              >
                <InputText
                  placeholder={t('presentation.restricted.code_placeholder')}
                  value={code}
                  name="code"
                  onChange={onCodeChange}
                />
              </Input>
              <div className={styles['button-group']}>
                <Button
                  onClick={submit}
                >
                  {
                    loading
                      ? (
                        <>
                          <Spinner />
                          <span className={styles.submitting}>
                            {t('project_access.submitting')}
                          </span>
                        </>
                      )
                      : t('presentation.restricted.verify')
                  }
                </Button>
                <Button
                  variant="ghost"
                  onClick={onBack}
                >
                  {t('project_access.back')}
                </Button>
              </div>
            </form>
          </>
        )
      }
      {
        formStep === 'ACCESS_REQUEST_SENT'
        && (
          <AccessFormRequestSent />
        )
      }
      {
        formStep === 'CREDIT_ERROR'
        && (
          <ProjectContentPopUpCreditError
            onClose={onClose}
          />
        )
      }
      {
        formStep === 'CALLBACK_RI'
        && (
          <ProjectContentPopUpCallBackRi
            calendar={calendar}
            phone={phone}
            onAccess={submit}
          />
        )
      }
    </Modal>
  );
}
