import axios, { CancelTokenSource } from 'axios';

import { BackendProject } from 'types/project';
import { getCookie } from 'utils/trackuser';

import axiosApi from '../axiosClient';

export const getProjectById = (id: number, source: CancelTokenSource) => axiosApi.get(`/confunding/projects/${id}/`, { cancelToken: source.token });
export const updateProjectById = (id: number, data: BackendProject) => axiosApi.put(`/confunding/projects/${id}/`, data);
export const uploadImageById = async (id: number, fileName: string, file: File) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('name', `project_${id}_image`);
  formData.append('file', new File([file], 'image', { type: file.type }));
  return axiosApi.post('/confunding/uploadFile', formData, config);
};
export const sendRegistrationSMS = (
  id: string,
  lastName: string,
  firstName: string,
  mail: string,
  phone: string,
) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('userLastname', lastName);
  formData.append('project_id', id);
  formData.append('userFirstname', firstName);
  formData.append('userEmail', mail);
  formData.append('userNumero', phone);
  const cookie = getCookie('csrftoken');
  if (cookie) {
    axios.defaults.headers.put['X-CSRFToken'] = cookie;
    axios.defaults.headers.post['X-CSRFToken'] = cookie;
  }
  return axios.post(`/cofunding/sendSms/${id}/test/`, formData, config);
};

export const verifyRegistrationSMS = (id: string, code: string, email: string) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('codeValidation', code);
  formData.append('project_id', id);
  formData.append('userEmail', email);
  const cookie = getCookie('csrftoken');
  if (cookie) {
    axios.defaults.headers.put['X-CSRFToken'] = cookie;
    axios.defaults.headers.post['X-CSRFToken'] = cookie;
  }
  return axios.post(`/cofunding/verifierSms/${id}/`, formData, config);
};

export const verifyToken = (token: string) => {
  const cookie = getCookie('csrftoken');
  if (cookie) {
    axios.defaults.headers.put['X-CSRFToken'] = cookie;
    axios.defaults.headers.post['X-CSRFToken'] = cookie;
  }
  return axios.post('/cofunding/verifyToken', { token });
};

export const sendPoll = ({
  id, phone, email, options,
}: {
  id: string, phone: string, email: string, options: string
}) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('projectId', id);
  formData.append('numtel', phone);
  formData.append('email', email);
  formData.append('resultChoice', options);

  const cookie = getCookie('csrftoken');
  if (cookie) {
    axios.defaults.headers.put['X-CSRFToken'] = cookie;
    axios.defaults.headers.post['X-CSRFToken'] = cookie;
  }
  return axios.post('/cofunding/pool/', formData, config);
};
