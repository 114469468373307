import React from 'react';

export default function Spinner() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="spin">
      <path d="M10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5Z" stroke="white" strokeOpacity="0.5" strokeWidth="3" />
      <path d="M20 10C20 8.6016 19.7067 7.21874 19.1391 5.94074C18.5714 4.66273 17.742 3.51799 16.7045 2.58043C15.6669 1.64288 14.4443 0.933359 13.1155 0.497692C11.7867 0.0620253 10.3813 -0.0901015 8.98999 0.0511359L9.29897 3.09477C10.2646 2.99674 11.2401 3.10233 12.1624 3.40471C13.0846 3.7071 13.9333 4.19956 14.6534 4.85029C15.3735 5.50102 15.9492 6.29555 16.3432 7.18258C16.7372 8.06961 16.9407 9.02941 16.9407 10H20Z" fill="white" />
    </svg>
  );
}
