import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/library/Button';
import styles from './ProjectManagerMode.module.css';

interface ProjectManagerModeProps {
  mode: string,
  setMode: (value: 'edition' | 'preview') => void,
}

export default function ProjectManagerMode({ mode, setMode }: ProjectManagerModeProps) {
  const { t } = useTranslation();

  const handleChange = () => setMode((mode === 'preview') ? 'edition' : 'preview');

  return (
    <div className={`${styles.banner} ${styles[mode]}`}>
      <p className={styles.text}>
        {
          (mode === 'edition')
            ? t('edition.title')
            : t('edition.previewMode')
        }
      </p>
      <Button
        className={styles.button}
        onClick={handleChange}
      >
        { mode === 'edition' ? 'Preview' : 'Edition' }
      </Button>
    </div>
  );
}
